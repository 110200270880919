import React, { useContext, useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { FaHeart, FaStar } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import AboutBg from "../../asset/AboutBg.png";
import Description_And_Review from "../../Componenet/AboutPage_Component/Description_And_Review";
import { CartContext } from "../../ContextApi/AddtocartContext";
import { WishlistContext } from "../../ContextApi/Context";
import { BaseUrl } from "../../ContextApi/Url/BaseUrl";

import Swal from 'sweetalert2';
function Product_Details() {
  const [rating, setRating] = useState(0);
  console.log(rating)
  const [isChecked, setIsChecked] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [errors, setErrors] = useState({});
   const [name, setName] = useState('');
  const [email, setEmail] = useState('');
 const [ErrorMsg, setErrorMsg] = useState('');
  const [quantity, setQuantity] = useState(0);
    const [selectedSize, setSelectedSize] = useState(null);
  const [selections, setSelections] = useState([]); 
  console.log(selections,"selections")
    const [review, setReview] = useState('');
 const location = useLocation();
  const { productId, productDetails } = location.state || {};
  console.log(productDetails,"productDetails")
 const [productData, setProductData] = useState([]);
 const {cart, addToCart } = useContext(CartContext);
const { wishlist, removeFromWishlist } = useContext(WishlistContext);
  // const handleAddToCart = (product) => {
  //   addToCart(product);
  // };
   const navigate = useNavigate();
 

//   const NavigateToBuyNow = (productIds) => {

//     const token = localStorage.getItem('token');
//     if (token) {
//   if (!Array.isArray(productIds)) {
//       productIds = [productIds]; // Ensure productIds is always an array
//     }
//     navigate("/AddDeliveryAddress", { state: { productIds } }); // Pass the product IDs in the state
//     } else {
//       alert("please Login ")
// }
    
//   };
//   const NavigateToBuyNow = (productId) => {
//     if (selections.length === 0) {
//     // alert("Please select at least one bag size.");
//     setErrorMsg('Please select at least one bag size.')
//     return; // Exit the function if no selections
//   }
//   const token = localStorage.getItem('authtoken');
//   if (token) {
//     // Create the productsData array containing the passed productId and selection
//     const productsData = [
//       {
//         productId,     // Pass the productId directly
//         selection:selections      // Pass the selection array directly (size and quantity)
//       }
//     ];

//     // Navigate to /AddDeliveryAddress and pass the productsData
//     navigate("/AddDeliveryAddress", { state: { productsData } });
//   } else {
//     alert("Please Login");
//   }
// };
const NavigateToBuyNow = (productId) => {
  // Check if selections are empty
  if (selections.length === 0) {
    setErrorMsg('Please select at least one bag size.');
    return; // Exit the function if no selections
  }

  const token = localStorage.getItem('authtoken');
  if (token) {
    // Create the productsData array containing the passed productId and selection
    const productsData = [
      {
        productId,     // Pass the productId directly
        selection: selections // Pass the selection array directly (size and quantity)
      }
    ];

    // Navigate to /AddDeliveryAddress and pass the productsData
    navigate("/AddDeliveryAddress", { state: { productsData } });
  } else {
    // Use SweetAlert2 to show login prompt
    Swal.fire({
      icon: 'warning',
      title: 'Login Required',
      text: 'Please log in to proceed with your purchase.',
      confirmButtonText: 'OK',
    });
  }
};

   const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
  };

  const handleConfirmClick = () => {
    setErrorMsg(''); // Reset error message

    if (selectedSize) {
      const newQuantity = quantity;

      if (newQuantity <= 0) {
        setErrorMsg('At Least 1 Quantity must be Purchase.');
        return; // Exit the function if quantity is 0 or less
      }

      setSelections((prevSelections) => {
        const existingIndex = prevSelections.findIndex(
          (selection) => selection.size === selectedSize
        );

        if (existingIndex !== -1) {
          // Update existing quantity
          const updatedSelections = [...prevSelections];
          updatedSelections[existingIndex] = {
            size: selectedSize,
            quantity: newQuantity
          };
          return updatedSelections;
        } else {
          // Add new selection
          return [...prevSelections, { size: selectedSize, quantity: newQuantity }];
        }
      });

      // Optionally reset selectedSize and quantity after confirmation
      setSelectedSize('');
      setQuantity(0);
    }
  };
//    const handleAddToCart = (product, selections) => {
  
//   // Check if selections are empty
//   if (selections.length === 0) {
//     // alert("Please select at least one bag size.");
//     setErrorMsg('Please select at least one bag size.')
//     return; // Exit the function if no selections
//   }

//   const productInCart = cart.find(item => item._id.toString() === product._id.toString());

//   if (productInCart) {
//     alert('Product is already available in Cart');
//   } else {
//     // Add product details and selections to the cart
//     const productWithSelections = {
//       ...product,
//       selections: selections // Add the selections to the product object
//     };
//     addToCart(productWithSelections);
//     alert('Product added to Cart');
//     removeFromWishlist(product._id); // Remove from wishlist
//   }
// };
  const handleAddToCart = (product, selections) => {
  // Check if selections are empty
  if (selections.length === 0) {
    setErrorMsg('Please select at least one bag size.');
    return; // Exit the function if no selections
  }

  const productInCart = cart.find(item => item._id.toString() === product._id.toString());

  if (productInCart) {
    // Show SweetAlert2 popup when the product is already in the cart
    Swal.fire({
      icon: 'info',
      title: 'Already in Cart',
      text: 'This product is already available in your cart.',
      confirmButtonText: 'OK'
    });
  } else {
    // Add product details and selections to the cart
    const productWithSelections = {
      ...product,
      selections: selections // Add the selections to the product object
    };
    addToCart(productWithSelections);

    // Show SweetAlert2 popup for successful addition to cart
    Swal.fire({
      icon: 'success',
      title: 'Added to Cart',
      text: 'Product added to your cart!',
      showConfirmButton: false,
      timer: 1500 // Close automatically after 1.5 seconds
    });

    // Remove from wishlist if needed
    removeFromWishlist(product._id);
  }
};
  useEffect(() => {
    // If productDetails is available in state, set it to local state
    if (productDetails) {
      setProductData(productDetails);
    }
  }, [productDetails]);

   useEffect(() => {
    // Retrieve data from local storage
    const localStorageData = JSON.parse(localStorage.getItem("dealerInfo")); // Replace with your local storage key
    if (localStorageData) {
      setName(localStorageData.shopName);
      setEmail(localStorageData.email);
    }
  }, []);
   const validateForm = () => {
        const errors = {};
        if (!rating) errors.rating = 'Rating is required';
        if (!review) errors.review = 'Review is required';
        if (!name) errors.name = 'Name is required';
        if (!email) errors.email = 'Email is required';
       
        return errors;
    };

//   const handleSubmit = async (e) => {
//        const token = localStorage.getItem('authtoken');

//     // Check if the token is found
//     if (!token) {
//         // Redirect to login or show a message to log in
//         alert('Please log in to continue.'); // You can replace this with a modal or redirect logic
//         return;
//     }
//     e.preventDefault();
//     const validationErrors = validateForm();
//     if (Object.keys(validationErrors).length > 0) {
//         setErrors(validationErrors);
//         return;
//     }
//     try {
//         const response = await fetch(`${BaseUrl}/reviews/addReview`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({
//                 rating, review,name, email, productId
//             }),
//         });

//         const data = await response.json();

//         if (response.ok) {
//             alert(data.message);
//             // Reset form
//             setRating(0);
//             setReview('');
//             setName('');
//             setEmail('');
           
//             setIsChecked(false);
//         } else {
//             console.error('There was an error adding the review:', data.error);
//         }
//     } catch (error) {
//         console.error('There was an error adding the review!', error);
//     }
 // };




const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    const token = localStorage.getItem('authtoken');

    // Check if the token is found
    if (!token) {
        Swal.fire({
            icon: 'warning',
            title: 'Unauthorized',
            text: 'Please log in to continue.',
        });
        return;
    }

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
    }

    // Get the dealerInfo and productId to check purchase eligibility
    const dealerInfo = JSON.parse(localStorage.getItem('dealerInfo'));
    const productIdNew = productDetails.productCode; // Replace with the actual product ID to check

    if (!dealerInfo || !dealerInfo.shopId) {
        console.error('Dealer Not Found');
        return;
    }

    // Fetch orders to check if the product was purchased
    const shopId = dealerInfo.shopId;

    try {
        const response = await fetch(`${BaseUrl}/Order/getOrdersByShopId/${shopId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Check if orders were found
        if (!data.success) {
            // Display a message if no orders are found for the provided ShopId
            if (data.message === "No orders found for the provided ShopId") {
                Swal.fire({
                    icon: 'error',
                    title: 'Not Eligible',
                    text: 'You are not eligible to review this product as you have not purchased it.',
                });
            } else {
                // Handle other messages
                Swal.fire({
                    icon: 'error',
                    title: 'Not Eligible',
                    text: 'You are not eligible to review this product as you have not purchased', // Show the specific message from the server
                });
            }
            return;
        }

        const hasPurchasedProduct = data.OrderList.some(order => 
            order.ProductDetails.some(product => product.ProductID === productIdNew)
        );

        if (!hasPurchasedProduct) {
            Swal.fire({
                icon: 'error',
                title: 'Not Eligible',
                text: 'You are not eligible to review this product as you have not purchased it.',
            });
            return;
        }

        // Proceed with submitting the review
        const reviewResponse = await fetch(`${BaseUrl}/reviews/addReview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Include the token in the header
            },
            body: JSON.stringify({
                rating, review, name, email, productId
            }),
        });

        const reviewData = await reviewResponse.json();

        if (reviewResponse.ok) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: reviewData.message,
            });
          
            // Reset form
            setRating(0);
            setReview('');
            setName('');
            setEmail('');
          setIsChecked(false);
          setErrors({});
        } else {
            console.error('There was an error adding the review:', reviewData.error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: reviewData.error || 'Failed to add the review.',
            });
        }
    } catch (error) {
        console.error('Error fetching orders:', error);
        Swal.fire({
            icon: 'error',
            title: 'Not Eligible',
            text: 'You are not eligible to review this product as you have not purchased it.',
        });
    }
};





  // Now you can use the productId to fetch product details or for other purposes
  console.log(productId);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
  const handleRating = (rate) => {
    setRating(rate);
  };
   const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
    const handleSelectChange = (e) => {
    console.log(e.target.value); // Handle selection
    setIsDropdownOpen(false); // Close dropdown on selection
  };
const originalPrice = productDetails.price; // Example original price, you can replace it with productDetails.price if applicable
const discount = productDetails.discount ? parseFloat(productDetails.discount) : 0;
const discountValue = (originalPrice * discount) / 100;
  const discountedPrice = originalPrice - discountValue;
  
  const { Commission } = productDetails;
  const bagSizes = [
    { size: "30kg", key: "dealer30Kg" },
    { size: "50kg", key: "dealer50Kg" },
    { size: "70kg", key: "dealer70Kg" },
  ];

  // Filter out the bag sizes based on available commission
  const availableBags = bagSizes.filter(bag => Commission[bag.key] > 0);
    const handleSizeClick = (size) => {
    setErrorMsg('')
    setSelectedSize(size);
    const selected = selections.find((selection) => selection.size === size);
    setQuantity(selected ? selected.quantity : 0);
  };

   const handleQuantityChange = (e) => {
    // Only update the state if the input is a positive number or zero
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(Number(value) || 1); // Default to 1 if empty
    }
  };
  return (
    <div>
      <div
        className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
        style={{ backgroundImage: `url(${AboutBg})` }}
      >
        <h1
          className="text-[#222222]  md:text-[50px] text-[40px]"
          style={{
            fontFamily: "Lexend",
           
            fontWeight: 500,
            lineHeight: "40px",
            textAlign: "left",
          }}
        >
          Product Details
        </h1>
        <p className="mt-4 text-lg ">
          Home &gt;<span className="text-[#BE740A]"> Product Details</span>
        </p>
      </div>
      <div className="flex flex-col justify-center gap-10 mt-20 md:flex-row">
        <div className="md:w-[569px] w-full h-[509px] bg-[#FFF9F0] rounded-lg flex justify-center items-center p-10 md:p-0">
         <img 
            src={productDetails?.selectedImages[0] || ''} // Use the first image or a default one
            alt="Product"
          className="w-full h-[400px] mt-2 rounded-lg object-cover" 
          />
        </div>
        <div>
          <div className='flex gap-5 pl-5 pr-5 md:pl-0 md:pr-0'>
  <p className="text-[#6D6D6D] text-[18px]" style={{ fontFamily: "DM Sans" }}>
  {discountedPrice < originalPrice ? (
    <>
      ₹{discountedPrice.toFixed(2)}{" "}
      <del style={{ marginLeft: "10px" }}>₹{originalPrice.toFixed(2)}</del>
    </>
  ) : (
    <>₹{originalPrice.toFixed(2)}</>
  )}
</p>

           {productDetails.discount && (
    <button
  style={{
    fontFamily: 'DM Sans, sans-serif',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'left',
    position: 'relative',
    top: '-5px',
  }}
  className='bg-[#735944] text-white p-1 rounded-lg'
>
  {`${productDetails.discount}% OFF`}
</button>
  )}
            
  </div>

      <div className='flex flex-col gap-10 md:flex-row'>
  <p
    style={{
      fontFamily: "Lexend, sans-serif",
      fontWeight: 700,
      lineHeight: "12px",
      textAlign: "left",
    }}
    className="mt-5 text-[20px] md:text-[42px] pl-5 pr-5 md:pl-0 md:pr-0"
  >
    {/* Check screen size using a window width check or use a custom hook to manage it */}
    {window.innerWidth < 768 
      ? productDetails.productName.slice(0, 10) + '...'  // Display first 10 letters and add ellipsis
      : productDetails.productName}  
  </p>
</div>


         
           {/* <div className="flex items-center mt-5">
            {[...Array(4)].map((_, index) => (
                        <svg key={index} className="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M10.868.793l2.137 6.58h6.936a.3.3 0 0 1 .176.543l-5.613 4.08 2.136 6.579a.3.3 0 0 1-.462.334L11 14.502l-5.614 4.082a.3.3 0 0 1-.462-.335l2.137-6.579-5.614-4.08a.3.3 0 0 1 .175-.543h6.937L10.53.793a.3.3 0 0 1 .338 0Z"/>
                        </svg>
                      ))}
           
          </div> */}
          <p
  style={{
    fontFamily: "DM Sans, sans-serif",
    fontSize: "16px",
    lineHeight: "26px",
    letterSpacing: "-0.01em",
    textAlign: "left",
  }}
  className="text-[#6D6D6D] md:w-[600px] pl-5 pr-5 md:pl-0 md:pr-0 mt-5"
>
  {productDetails.productDescription.slice(0, 300)}
  {productDetails.productDescription.length > 300 && '...'} {/* Add ellipsis if text is longer than 100 characters */}
</p>
 <div className="pl-5 pr-5 mt-5 md:pl-0 md:pr-0">
            <p
              className="text-[16px]"
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "26.04px",
                textAlign: "left",
              }}
            >
              Category:{" "}
              <span className="text-[#6D6D6D] text-[16px]">{productDetails.category}</span>
            </p>
            
          </div>
          <p className="flex items-center gap-2 pl-5 pr-5 mt-5 md:pl-0 md:pr-0">
            <span
              className="text-[16px] text-[#222222]"
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 700,
                lineHeight: "12px",
                textAlign: "left",
              }}
            >
              Availability:
            </span>
            <span className="flex items-center">
              <input
                type="checkbox"
                style={{ marginRight: "5px" }}
                className="w-[14px] h-[14px]"
                checked={true}
                readOnly
              />
              <span
                style={{
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20.83px",
                }}
                className="text-[#6D6D6D]"
              >
                In Stock
              </span>
            </span>
          </p>

      
        
           <div className="pl-5 pr-5 mt-5 md:pl-0 md:pr-0">
           <div>
            <p
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "18px",
                fontWeight: 500,
                textAlign: "left",
              }}
              className='text-[#6D6D6D]'
            >
              Bag Size
            </p>
            <div className='flex gap-3 mt-3'>
          {availableBags.map((bag) => {
            const confirmed = selections.find(
              (selection) => selection.size === bag.size
            );
            return (
              <button
                key={bag.size}
                onClick={() => handleSizeClick(bag.size)}
                className={`px-4 py-2 border rounded ${
                  selectedSize === bag.size ? 'bg-gray-300' : 'bg-white'
                }`}
              >
                {bag.size}{" "}
                {confirmed && `(QTY :${confirmed.quantity})`}

              </button>
            );
          })}
               
                  
        </div>
          </div>
           {selectedSize && (
        <div className='flex gap-5'>
          <div className='mt-5'>
            <p
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "18px",
                fontWeight: 500,
                textAlign: "left",
              }}
              className='text-[#6D6D6D]'
            >
              Quantity
            </p>
           <div className='flex items-center gap-3 mt-3'>
      <button
        onClick={decreaseQuantity}
        className='px-3 py-1 bg-gray-200 border rounded'
      >
        -
      </button>
      <input
        type="text"
        value={quantity}
        onChange={handleQuantityChange}
        className='w-12 text-center border rounded'
      />
      <button
        onClick={increaseQuantity}
        className='px-3 py-1 bg-gray-200 border rounded'
      >
        +
      </button>
    </div>
          </div>
          <div className='mt-9'>
            <button
              onClick={handleConfirmClick}
              className="px-4 py-2 mt-5 text-white bg-green-500 rounded"
            >
              Confirm
            </button>
          </div>
        </div>
      )}
          </div>
           <p className="pl-5 pr-5 md:pl-0 md:pr-0">
                  {ErrorMsg !=='' && <p className="mt-2 text-red-500">{ErrorMsg}</p>}
                </p>

        <div className="flex gap-5 pl-5 pr-5 mt-5 md:pl-0 md:pr-0">
  <button 
    className="w-[165px] h-[54px] text-[14px] border border-black rounded-[27px] hover:bg-gray-100"
    onClick={() => handleAddToCart(productDetails, selections)}
  >
    ADD TO CART
  </button>
  
  <button
    className="w-[176px] h-[54px] bg-[#FF9F00] text-white rounded-full"
    onClick={() => NavigateToBuyNow(productDetails._id)}
  >
    BUY NOW
  </button>
          </div>
         
         
          
        </div>
      </div>

      <div className="p-2 mt-5 md:mt-10 md:pr-20 md:pl-32 md:p-0">
        <Description_And_Review productDescription={productDetails.productDescription} productCode={productDetails._id} />
      </div>
    <div className="mt-10 w-full max-w-[1155px] h-auto bg-[#FFF9F0] flex mx-auto p-5 sm:p-10 rounded-[27px]">
    <div className="w-full">
        <p className="text-[28px] sm:text-[36px] font-medium leading-[20px] text-left" style={{ fontFamily: 'Lexend' }}>
            Add a review
        </p>
        <div className="flex flex-col gap-2 mt-5 sm:flex-row">
            <p
                style={{
                    fontFamily: 'DM Sans',
                    fontSize: '14px',
                    sm: '16px',
                    fontWeight: 400,
                    lineHeight: '20.83px',
                    textAlign: 'left',
                }}
                className="text-[#6D6D6D]"
            >
                Your Rating
            </p>
            <div className="flex mt-1">
                {[...Array(5)].map((_, index) => (
                    <FaStar
                        key={index}
                        className={`w-[15.54px] h-[15.54px] cursor-pointer ${index < rating ? 'text-yellow-500' : 'text-gray-300'}`}
                        onClick={() => handleRating(index + 1)}
                    />
                ))}
            </div>
        </div>
        {errors.rating && <p className="text-red-500">{errors.rating}</p>}
        <div className="mt-5">
            <textarea
                placeholder='Write a Message'
                className="block w-full h-[142px] py-3 pl-4 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-white"
                rows="4"
                value={review}
                onChange={(e) => setReview(e.target.value)}
            ></textarea>
            {errors.review && <p className="text-red-500">{errors.review}</p>}
            <div className='flex flex-col justify-between gap-2 mt-10 sm:flex-row'>
                <div className="relative mb-4 w-full sm:w-[48%]">
                    <input
                        type='text'
                        placeholder='Your Name'
                        className="block w-full py-2 pl-4 pr-10 bg-white border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <CiUser className="text-[#6D6D6D]  w-[25px] h-[20px]" />
                    </div>
                    {errors.name && <p className="text-red-500">{errors.name}</p>}
                </div>

                <div className="relative mb-4 w-full sm:w-[48%]">
                    <input
                        type='email'
                        placeholder='Email Address'
                        className="block w-full py-2 pl-4 pr-10 bg-white border border-gray-300 rounded-full focus:outline-none focus:border-blue-500"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <MdEmail className="text-[#6D6D6D] w-[25px] h-[20px]" />
                    </div>
                    {errors.email && <p className="text-red-500">{errors.email}</p>}
                </div>
            </div>

            <div className="mt-5">
                <button
                    className='w-full sm:w-[164px] h-[54px] text-white rounded-[27px] bg-[#735944]'
                    onClick={handleSubmit}
                >
                    POST REVIEW
                </button>
            </div>
        </div>
    </div>
</div>

    </div>
  );
}

export default Product_Details;
