import React from 'react'
import AboutBg from '../../asset/AboutBg.png';
function PrivacyPolicy() {
  return (
    <div>
      <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222] md:text-[50px] text-[30px]  "
        style={{
          fontFamily: 'Lexend',
          // fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
       Terms And Conditions
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'> Terms And Conditions</span></p>
          </div>
          <div className='flex justify-center p-5 mt-10 mb-10' >
               <div >
            <p className="font-lexend text-[32px] font-medium leading-[40px] text-left">
  The standard Lorem Ipsum passage
</p>

<p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan turpis posuere cursus ultricies. Ut nunc justo,<br></br>
  faucibus eget elit quis, vehicula rhoncus nulla. Phasellus convallis sem nec facilisis commodo. Fusce ut molestie turpis.<br></br>
  Suspendisse aliquet sed massa in vulputate. Quisque gravida suscipit tincidunt.
</p>
              <p className='mt-5 font-lexend text-[32px] font-medium leading-[40px] '>At vero eos et accusamus et iusto odio dignissimos</p>
              <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan turpis posuere cursus ultricies. Ut nunc justo,<br></br>
  faucibus eget elit quis, vehicula rhoncus nulla. Phasellus convallis sem nec facilisis commodo. Fusce ut molestie turpis.<br></br>
  Suspendisse aliquet sed massa in vulputate. Quisque gravida suscipit tincidunt.
              </p>
              



               <p className='mt-5 font-lexend text-[32px] font-medium leading-[40px] '>At vero eos et accusamus et iusto odio dignissimos</p>
              <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan turpis posuere cursus ultricies. Ut nunc justo,<br></br>
  faucibus eget elit quis, vehicula rhoncus nulla. Phasellus convallis sem nec facilisis commodo. Fusce ut molestie turpis.<br></br>
  Suspendisse aliquet sed massa in vulputate. Quisque gravida suscipit tincidunt.
              </p>
              



               <p className='mt-5 font-lexend text-[32px] font-medium leading-[40px] '>At vero eos et accusamus et iusto odio dignissimos</p>
              <p className="font-dmsans text-[20px] font-normal leading-[26px] text-left text-[#6D6D6D]">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi accumsan turpis posuere cursus ultricies. Ut nunc justo,<br></br>
  faucibus eget elit quis, vehicula rhoncus nulla. Phasellus convallis sem nec facilisis commodo. Fusce ut molestie turpis.<br></br>
  Suspendisse aliquet sed massa in vulputate. Quisque gravida suscipit tincidunt.
</p>
          </div>
          </div>
         
    </div>
  )
}

export default PrivacyPolicy
