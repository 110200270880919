import React, { useContext, useEffect, useState } from 'react';
import { FaHeart } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../ContextApi/AddtocartContext';
import { WishlistContext } from '../../ContextApi/Context';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import Swal from 'sweetalert2';
const ViewProduct = ({ isModalOpen, onClose, productId }) => {
   const [productDetails, setProductDetails] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
   const [ErrorMsg, setErrorMsg] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [selections, setSelections] = useState([]); 
  console.log(selections,"selections")
  const { cart, addToCart } = useContext(CartContext);
  const { wishlist, removeFromWishlist } = useContext(WishlistContext);
  const navigate = useNavigate();
console.log(selections)
  useEffect(() => {
    if (isModalOpen && productId) {
      fetch(`${BaseUrl}/products/GetSingleproductsForEcommerce`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId }),
      })
        .then(response => response.json())
        .then(data => {
          setProductDetails(data.SingleProductList);
        })
        .catch(error => {
          console.error("There was an error fetching the product details!", error);
        });
    }
  }, [isModalOpen, productId]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'unset'; // Enable scrolling
    }
    return () => {
      document.body.style.overflow = 'unset'; // Ensure scrolling is enabled when component unmounts
    };
  }, [isModalOpen]);

  useEffect(() => {
    if (productDetails) {
      const expiryDate = new Date(productDetails.expiryDate).getTime();

      const timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = expiryDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          clearInterval(timer);
          setCountdown(null);
        } else {
          setCountdown({ days, hours, minutes, seconds });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [productDetails]);

  if (!isModalOpen || !productDetails) return null;

  const originalPrice = productDetails.price;
  const discount = productDetails.discount ? parseFloat(productDetails.discount) : 0;
  const discountValue = (originalPrice * discount) / 100;
  const discountedPrice = originalPrice - discountValue;
   
  const { Commission } = productDetails;
  const bagSizes = [
    { size: "30kg", key: "dealer30Kg" },
    { size: "50kg", key: "dealer50Kg" },
    { size: "70kg", key: "dealer70Kg" },
  ];

  // Filter out the bag sizes based on available commission
  const availableBags = bagSizes.filter(bag => Commission[bag.key] > 0);

  const handleSizeClick = (size) => {
    setErrorMsg('')
    setSelectedSize(size);
    const selected = selections.find((selection) => selection.size === size);
    setQuantity(selected ? selected.quantity : 0);
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
  };

    const handleConfirmClick = () => {
    setErrorMsg(''); // Reset error message

    if (selectedSize) {
      const newQuantity = quantity;

      if (newQuantity <= 0) {
        setErrorMsg('At Least 1 Quantity must be Purchase.');
        return; // Exit the function if quantity is 0 or less
      }

      setSelections((prevSelections) => {
        const existingIndex = prevSelections.findIndex(
          (selection) => selection.size === selectedSize
        );

        if (existingIndex !== -1) {
          // Update existing quantity
          const updatedSelections = [...prevSelections];
          updatedSelections[existingIndex] = {
            size: selectedSize,
            quantity: newQuantity
          };
          return updatedSelections;
        } else {
          // Add new selection
          return [...prevSelections, { size: selectedSize, quantity: newQuantity }];
        }
      });

      // Optionally reset selectedSize and quantity after confirmation
      setSelectedSize('');
      setQuantity(0);
    }
  };
  const HandleClose = () => {
    setSelections([])
    onClose()
  }
  const handleAddToCart = (product, selections) => {
   debugger
  // Check if selections are empty
  if (selections.length === 0) {
    // alert("Please select at least one bag size.");
    setErrorMsg('Please select at least one bag size.')
    return; // Exit the function if no selections
  }

  const productInCart = cart.find(item => item._id.toString() === product._id.toString());

  if (productInCart) {
    alert('Product is already available in Cart');
  } else {
    // Add product details and selections to the cart
    const productWithSelections = {
      ...product,
      selections: selections // Add the selections to the product object
    };
    addToCart(productWithSelections);
    alert('Product added to Cart');
    removeFromWishlist(product._id); // Remove from wishlist
  }
};



 const NavigateToBuyNow = (productId) => {
  if (selections.length === 0) {
    // Use SweetAlert to show the error message
    setErrorMsg('Please select at least one bag size.'); // Optional, can be kept if needed for UI state
    Swal.fire({
      icon: 'warning',
      title: 'Selection Required',
      text: 'Please select at least one bag size.',
      confirmButtonText: 'Okay',
    });
    return; // Exit the function if no selections
  }

  const token = localStorage.getItem('authtoken');
  if (token) {
    // Create the productsData array containing the passed productId and selection
    const productsData = [
      {
        productId,     // Pass the productId directly
        selection: selections      // Pass the selection array directly (size and quantity)
      }
    ];

    // Navigate to /AddDeliveryAddress and pass the productsData
    navigate("/AddDeliveryAddress", { state: { productsData } });
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Login Required',
      text: 'Please log in to continue.',
      confirmButtonText: 'Login',
    });
  }
};
  
   const handleQuantityChange = (e) => {
    // Only update the state if the input is a positive number or zero
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(Number(value) || 1); // Default to 1 if empty
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center  p-4 overflow-y-auto bg-black bg-opacity-50 scrollbar-hidden]">
  <div className="relative flex flex-col justify-center w-full max-w-6xl gap-5 p-5 mt-5 bg-white rounded-lg md:flex-row md:gap-10 md:p-10 md:mt-20">
    {/* Product Image Section */}
    <div className="md:w-[569px] w-full h-[509px] bg-[#FFF9F0] rounded-lg md:flex justify-center items-center p-10 md:p-0 hidden md:visible">
         <img 
            src={productDetails?.selectedImages[0] || ''} // Use the first image or a default one
            alt="Product"
          className="w-full h-[400px] mt-2 rounded-lg object-cover" 
          />
        </div>
    {/* Product Details Section */}
    <div className="w-full">
      {/* Pricing Section */}
      <div className='flex items-center gap-5'>
        <p className="text-[#6D6D6D] text-[16px] md:text-[18px]" style={{ fontFamily: "DM Sans" }}>
          {discountedPrice < originalPrice ? (
            <>
              ₹{discountedPrice.toFixed(2)}{" "}
              <del style={{ marginLeft: "10px" }}>₹{originalPrice.toFixed(2)}</del>
            </>
          ) : (
            <>₹{originalPrice.toFixed(2)}</>
          )}
        </p>

        {productDetails.discount && (
          <button
            style={{
              fontFamily: 'DM Sans, sans-serif',
              fontSize: '12px',
              fontWeight: 700,
              textAlign: 'left',
              top: '-5px',
            }}
            className='bg-[#735944] text-white p-1 rounded-lg text-xs md:text-sm'
          >
            {`${productDetails.discount}% OFF`}
          </button>
        )}
      </div>

      {/* Product Name */}
      <div className='flex items-center justify-between'>
        <p
          style={{
            fontFamily: "Lexend, sans-serif",
            fontSize: "28px",
            mdFontSize: "42px",
            fontWeight: 700,
            lineHeight: "1.2",
          }}
          className="mt-5"
        >
          {productDetails.productName}
        </p>
        {/* Heart button can be added back later */}
      </div>

      {/* Rating Section */}
    

      {/* Product Description */}
      <p className="text-[#6D6D6D] text-sm md:text-base w-full md:w-[600px] mt-3">
        {productDetails.productDescription.slice(0, 300)}
        {productDetails.productDescription.length > 300 && '...'}
      </p>

      {/* Availability */}
      <p className="flex items-center gap-2 mt-5">
        <span className="text-[14px] md:text-[16px] text-[#222222]" style={{ fontFamily: "DM Sans, sans-serif", fontWeight: 700 }}>
          Availability:
        </span>
        <span className="flex items-center">
          <input type="checkbox" className="w-[14px] h-[14px]" checked={true} readOnly />
          <span className="text-[14px] md:text-[16px] text-[#6D6D6D]">In Stock</span>
        </span>
      </p>

      {/* Bag Size & Quantity */}
      <div className="mt-5">
        <p className="text-[#6D6D6D] text-sm md:text-lg">Bag Size</p>
        <div className="flex flex-wrap gap-3 mt-3">
          {availableBags.map((bag) => {
            const confirmed = selections.find((selection) => selection.size === bag.size);
            return (
              <button key={bag.size} onClick={() => handleSizeClick(bag.size)} className={`px-4 py-2 border rounded ${selectedSize === bag.size ? 'bg-gray-300' : 'bg-white'}`}>
                {bag.size} {confirmed && `(QTY :${confirmed.quantity})`}
              </button>
            );
          })}
        </div>

        {selectedSize && (
          <div className="flex gap-5 mt-5">
            <div>
              <p className="text-[#6D6D6D] text-sm md:text-lg">Quantity</p>
              <div className="flex items-center gap-3 mt-3">
                <button onClick={decreaseQuantity} className="px-3 py-1 bg-gray-200 border rounded">-</button>
                <input type="text" value={quantity} onChange={handleQuantityChange} className="w-12 text-center border rounded" />
                <button onClick={increaseQuantity} className="px-3 py-1 bg-gray-200 border rounded">+</button>
              </div>
            </div>
            <div className="md:mt-9">
              <button onClick={handleConfirmClick} className="px-4 py-2 mt-5 text-white bg-green-500 rounded">Confirm</button>
            </div>
          </div>
        )}
      </div>

      {/* Error Message */}
      {ErrorMsg && <p className="mt-2 text-red-500">{ErrorMsg}</p>}

      {/* Add to Cart / Buy Now Buttons */}
      <div className="flex gap-5 mt-5">
        <button className="w-full md:w-[165px] h-[54px] text-[14px] border border-black rounded-[27px] hover:bg-gray-100" onClick={() => handleAddToCart(productDetails, selections)}>ADD TO CART</button>
        <button className="w-full md:w-[176px] h-[54px] bg-[#FF9F00] text-white rounded-full" onClick={() => NavigateToBuyNow(productDetails._id)}>BUY NOW</button>
      </div>

      {/* Discount Countdown */}
      {countdown && (
        <div className="mt-5">
          <span className="text-lg text-[#FF9F00] font-bold">Discount ends in:</span>
          <span className="text-md text-[#6D6D6D]">{countdown.days}d {countdown.hours}h {countdown.minutes}m {countdown.seconds}s</span>
        </div>
      )}
    </div>

    {/* Close Button */}
    <button className="absolute top-2 right-2" onClick={HandleClose}>
      <MdCancel className="h-7 w-7" />
    </button>
  </div>
</div>

  );
};

export default ViewProduct;
