
import React, { useContext, useEffect, useState } from "react";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../ContextApi/AddtocartContext";
import { useWishlist } from "../../ContextApi/Context";
import { BaseUrl } from "../../ContextApi/Url/BaseUrl";
import ViewProduct from "../../Pages/ProductDetails/ViewProduct";
import Pagination from "../../Pages/Shop/Pagination";
import productNotfound from "../../asset/productNotfound.png";
import Swal from 'sweetalert2';
function Shop_Cards({ searchTerm, selectedCategory }) {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const productsPerPage = 10;
  const navigate = useNavigate();
 const {cart, addToCart } = useContext(CartContext);
const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
  // const handleAddToCart = (product) => {
  //   addToCart(product);
  // };
  const handleAddToCart = (product) => {
  const productInCart = cart.find(item => item._id.toString() === product._id.toString());

  if (productInCart) {
    alert('Product already available in Cart');
  } else {
    addToCart(product);
    alert('Product Added in Cart');
    removeFromWishlist(product._id); // Remove from wishlist
  }
};

  // Fetch product data from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${BaseUrl}/products/getEcommerceProducts`);
        const data = await response.json();
        if (data.result) {
          setProducts(data.productsList);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Reset the current page when searchTerm or selectedCategory changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedCategory]);

  // Filter products based on searchTerm and selectedCategory
  const filteredProducts = products.filter(
    (product) =>
      product.productName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === "" || product.category === selectedCategory)
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const NavigateToShopDetails = async (productId) => {
  if (productId) {
    try {
      const response = await fetch(`${BaseUrl}/products/GetSingleproductsForEcommerce`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setProductDetails(data.SingleProductList);

      // Navigate to ShopDetails with the product details
      navigate("/ShopDetails", { state: { productId, productDetails: data.SingleProductList } });
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  }
};


  const handleViewProduct = (productId) => {
    setSelectedProductId(productId);
    setIsViewProductModalOpen(true);
  };

  const handleCloseViewProduct = () => {
    setIsViewProductModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to toggle the wishlist state
//  const toggleWishlist = (product) => {
  

//   let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
//   const productIndex = storedWishlist.findIndex((item) => item._id === product._id);

//   if (productIndex === -1) {
//     // Add product to wishlist
//     const productWithDate = { ...product, dateAdded: new Date().toISOString() };
//     storedWishlist.push(productWithDate);
//     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
//     addToWishlist(productWithDate);
//     alert("Product added to wishlist!");
//   } else {
//     // Remove product from wishlist
//     storedWishlist.splice(productIndex, 1);
//     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
//     removeFromWishlist(product._id);
//     alert("Product removed from wishlist!");
//   }
// };
const toggleWishlist = (product) => {
  let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  const productIndex = storedWishlist.findIndex((item) => item._id === product._id);

  if (productIndex === -1) {
    // Add product to wishlist
    const productWithDate = { ...product, dateAdded: new Date().toISOString() };
    storedWishlist.push(productWithDate);
    localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    addToWishlist(productWithDate);

    // Show SweetAlert for adding to wishlist
    Swal.fire({
      icon: 'success',
      title: 'Added to Wishlist',
      text: 'Product added to your wishlist!',
      showConfirmButton: false,
      timer: 1500
    });
  } else {
    // Remove product from wishlist
    storedWishlist.splice(productIndex, 1);
    localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    removeFromWishlist(product._id);

    // Show SweetAlert for removing from wishlist
    Swal.fire({
      icon: 'info',
      title: 'Removed from Wishlist',
      text: 'Product removed from your wishlist.',
      showConfirmButton: false,
      timer: 1500
    });
  }
};
  // Function to check if a product is in the wishlist
  const isProductInWishlist = (productId) => {
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    return wishlist.some((item) => item._id === productId);
  };

  return (
    <>
      <div className="flex flex-wrap justify-center gap-5 md:pr-20 md:pl-20">
       
     
        {filteredProducts.length > 0 ? (
  currentProducts.map((product) => (
    <div
      key={product._id}
      className="relative w-[274px] h-[415px] bg-white p-5 rounded-lg transition-shadow duration-300 hover:scale-105 shadow-lg"
      onMouseEnter={() => setHoveredProductId(product._id)}
      onMouseLeave={() => setHoveredProductId(null)}
    >
      <div className="h-[242px] bg-[#FFF9F0] p-5 rounded-lg">
        <div className="relative flex items-center justify-center ">
          <img
            src={product.selectedImages[0]} // Use the first image or a default one
            alt="Product"
            className="w-[173px] h-[172px] mt-2 rounded-lg"
          />
          {hoveredProductId === product._id && (
            <div className="absolute top-0 right-0 flex flex-col items-end transition-transform duration-300">
              <button
                className="w-[51px] h-[51px] rounded-full bg-white mb-2 flex justify-center items-center hover:bg-[#FF9F00] hover:text-white"
                onClick={() => handleViewProduct(product._id)}
              >
                <IoEyeOutline className="w-[28px] h-[28px]" />
              </button>
              <button
                className={`w-[51px] h-[51px] rounded-full flex justify-center items-center  
                  ${isProductInWishlist(product._id) ? 'text-black bg-white' : 'text-black bg-white'}
                   hover:text-white`}
                onClick={() => toggleWishlist(product)}
              >
                {isProductInWishlist(product._id) ? (
                  <FaHeart className={`w-[22px] h-[22px] text-red-600`} />
                ) : (
                  <CiHeart className={`w-[28px] h-[28px] text-black`} />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
      <div>
        <p
          style={{
            fontFamily: "DM Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18.23px",
          }}
          className="mt-2 text-[#6D6D6D] text-center"
        >
          {product.category}
        </p>
        <p
          style={{
            fontFamily: "Lexend, sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "42px",
          }}
          className="mt-2 text-center"
        >
          {product.productName.length > 7
            ? `${product.productName.slice(0, 7)}...`
            : product.productName}
        </p>

        <div className="flex justify-center gap-2">
          <p
            style={{
              fontFamily: "DM Sans, sans-serif",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18.23px",
            }}
            className="text-[#6D6D6D] text-center"
          >
            {product.discount ? (
              <del>₹{product.price.toFixed(2)}</del>
            ) : (
              `₹${product.price.toFixed(2)}`
            )}
          </p>

          {product.discount && (
            <p
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "18.23px",
              }}
              className="text-[#6D6D6D] text-center"
            >
              ₹{(product.price * (1 - product.discount / 100)).toFixed(2)}
            </p>
          )}
          {product.discount && (
            <button
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
                position: "relative",
                top: "-5px",
              }}
              className="bg-[#735944] text-white p-1 rounded-lg"
            >
              {`${product.discount}% OFF`}
            </button>
          )}
        </div>

        <div className="mt-1 text-center">
          {hoveredProductId === product._id ? (
            <button
              className="w-[118px] h-[41px] bg-[#FF9F00] text-white rounded-[50px] p-2"
              onClick={() => NavigateToShopDetails(product._id)} // pass the productId here
            >
              SHOP NOW
            </button>
          ) : (
          <div className="flex items-center justify-center gap-2">
  <p>({product.reviewCount || 0} Reviews)</p>
  <div className="flex items-center">
    {(() => {
      // Ensure averageRating is a valid non-negative integer
      const ratingCount = Math.max(0, Math.floor(Number(product.averageRating) || 0));

      return (
        <>
          {[...Array(ratingCount)].map((_, index) => (
            <svg
              key={index}
              className="w-4 h-4 text-yellow-300 me-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M10.868.793l2.137 6.58h6.936a.3.3 0 0 1 .176.543l-5.613 4.08 2.136 6.579a.3.3 0 0 1-.46.335L11 14.373l-5.19 3.87a.3.3 0 0 1-.46-.335l2.136-6.579-5.613-4.08a.3.3 0 0 1 .176-.543h6.936z" />
            </svg>
          ))}
        </>
      );
    })()}
  </div>
</div>

          )}
        </div>
      </div>
    </div>
  ))
) : (
  <div className="mt-5 text-center">
    <p className="text-xl font-semibold text-gray-300"> <img
            src={productNotfound}
            alt="Wheat"
            className="w-[350px] h-[200px] mr-2 rounded-lg"
          /></p>
  </div>
)}

      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      {isViewProductModalOpen && (
        <ViewProduct isModalOpen={isViewProductModalOpen} productId={selectedProductId} onClose={handleCloseViewProduct} />
      )}
    </>
  );
}

export default Shop_Cards;

