import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function AddNewPayment({ isModalOpen, onClose, selectedOrderId,orderDetail }) {
  console.log(orderDetail, "orderDetail")
  console.log(selectedOrderId,"selectedOrderId")
  const [transactionData, setTransactionData] = useState([]);
  const [currentSection, setCurrentSection] = useState('transaction'); // Default section is 'transaction'
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
 const [bankName, setBankName] = useState("");
  const [accountHolderNameBank, setAccountHolderNameBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumbercheque, setAccountNumbercheque] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [paymentAmountBank, setPaymentAmountBank] = useState("");
  const [bankNameCheque, setBankNameCheque] = useState("");
  const [accountHolderNameCheque, setAccountHolderNameCheque] = useState("");
  const [records, setRecords] = useState([]);
  console.log(records)
  const [chequeNumber, setChequeNumber] = useState("");
  const [paymentAmountCheque, setPaymentAmountCheque] = useState("");
  const [paymentAmountCash, setPaymentAmountCash] = useState("");
  const [error, setError] = useState('');
   const [errors, setErrors] = useState({
    bankName: "",
    accountHolderNameBank: "",
    accountNumber: "",
    ifscCode: "",
     paymentAmountBank: "",
    paymentAmountCash:""
  });

   const fetchPaymentData = async () => {
    try {
      const response = await fetch(`${BaseUrl}/payments/list`);
      const data = await response.json();
      if (data.success) {
        setRecords(data.payments);
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchPaymentData();
  }, []);
  console.log(errors,"errors")
  useEffect(() => {
    if (isModalOpen && selectedOrderId) {
      // Fetch transaction data when the modal is opened and an order ID is selected
      fetch(`${BaseUrl}/transactions/${selectedOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authtoken')}` // Add your auth token here
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.result) {
          setTransactionData(data.data[0]?.TransactionData.reverse() || []); // Adjust according to data structure
        } else {
          // Handle the case where no records are found
          setTransactionData([]);
        }
      })
      .catch(error => {
        // Handle fetch errors
        console.error('Error fetching transaction data:', error);
        setTransactionData([]);
      });
    }

    if (!isModalOpen) {
      // Clear data when modal is closed
      setTransactionData([]);
    }

    return () => {
      // Cleanup on unmount
      setTransactionData([]);
    };
  }, [isModalOpen, selectedOrderId]);

  if (!isModalOpen) return null;

  const duePayment = (transactionData && transactionData.length > 0) ? parseFloat(transactionData[0].Duepayment) : 0;
  const duePaymentAmount = duePayment.toFixed(2);

  const firstRecord = (transactionData && transactionData.length > 0)
    ? transactionData[0]
    : null;
 const duePaymentnew = firstRecord && firstRecord.Duepayment !== null 
  ? parseFloat(firstRecord.Duepayment).toFixed(2) 
  : '0.00'; // Ensure it's a string for consistent formatting

 const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    
    // Allow only numbers
    const numericValue = value.replace(/[^0-9]/g, '');
    
    // You can add validation for length or other criteria here if needed
    setAccountNumber(numericValue);

    // Example validation: Set an error if the account number is empty or invalid
    if (numericValue === '') {
      setErrors({
        ...errors,
        accountNumber: 'Account number is required'
      });
    } else {
      setErrors({ ...errors, accountNumber: '' });
    }
  };
  const handleAccountNumberChangeCheque = (e) => {
    const value = e.target.value;
    
    
    const numericValue = value.replace(/[^0-9]/g, '');
    
    
    setAccountNumbercheque(numericValue);

   
  
  };

  

   const handleAmountChange = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and check for validity
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    if (parseFloat(numericValue) > duePaymentnew) {
      setErrors({
        ...errors,
        paymentAmountBank: `Amount cannot exceed ${duePaymentnew}`
      });
    } else {
      setErrors({ ...errors, paymentAmountBank: '' });
      setPaymentAmountBank(numericValue);
    }
  };

  const handleAmountChangeForcash = (e) => {
  const value = e.target.value;

  // Allow only numeric values and handle empty input
  if (/^\d*\.?\d*$/.test(value)) {
    setPaymentAmountCash(value);
    setErrors((prevErrors) => ({ ...prevErrors, paymentAmountCash: "" }));
  } else {
    setErrors((prevErrors) => ({
      ...prevErrors,
      paymentAmountCash: "Please enter a valid amount.",
    }));
  }
};
    const handleInputChangeForCheckAmount = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and check for validity
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    if (parseFloat(numericValue) > duePaymentnew) {
      setErrors({
        ...errors,
        paymentAmountCheque: `Amount cannot exceed ${duePaymentnew}`
      });
    } else {
      setErrors({ ...errors, paymentAmountCheque: '' });
      setPaymentAmountCheque(numericValue);
    }
  };
  

   const handleInputChangeForCashAmount = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and check for validity
    const numericValue = value.replace(/[^0-9.]/g, '');
    
    if (parseFloat(numericValue) > duePaymentnew) {
      setErrors({
        ...errors,
        paymentAmountCash: `Amount cannot exceed ${duePaymentnew}`
      });
    } else {
      setErrors({ ...errors, paymentAmountCash: '' });
      setPaymentAmountCash(numericValue);
    }
  };
    const handleInputChange = (e, setter) => {
    setter(e.target.value);
  };

  const handleInputChangeForcheckNumber = (e, setState) => {
    // Get the input value
    const value = e.target.value;

    // Filter to allow only digits
    const numericValue = value.replace(/[^0-9]/g, '');

    // Update state with filtered numeric value
    setState(numericValue);

    // Simple validation for required field
    if (numericValue === '') {
      setErrors({ ...errors, chequeNumber: 'Cheque Number is required' });
    } else {
      setErrors({ ...errors, chequeNumber: '' });
    }
  };

   const validateFieldsForBank = () => {
    let isValid = true;
    const newErrors = {
      bankName: "",
      accountHolderNameBank: "",
      accountNumber: "",
      ifscCode: "",
      paymentAmountBank: ""
    };

    if (!bankName) {
      newErrors.bankName = "Bank Name is required";
      isValid = false;
    }
    if (!accountHolderNameBank) {
      newErrors.accountHolderNameBank = "Account Holder Name is required";
      isValid = false;
    }
    if (!accountNumber) {
      newErrors.accountNumber = "Account Number is required";
      isValid = false;
    }
    if (!ifscCode) {
      newErrors.ifscCode = "IFSC Code is required";
      isValid = false;
    }
    if (!paymentAmountBank) {
      newErrors.paymentAmountBank = "Payment  Amount is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const validateFieldsForCheque = () => {
  const errors = {};
    if (!bankNameCheque) errors.bankNameCheque = 'Bank Name is required';
    if (!accountNumbercheque) errors.accountNumbercheque = 'Account Number is required';
  if (!accountHolderNameCheque) errors.accountHolderNameCheque = 'Account Holder Name is required';
  if (!chequeNumber) errors.chequeNumber = 'Cheque Number is required';
  if (!paymentAmountCheque || isNaN(paymentAmountCheque)) errors.paymentAmountCheque = 'Valid Payment  Amount is required';

  setErrors(errors);
  return Object.keys(errors).length === 0;
  };
  
   const handleClosetheModel = () => {
    
    onClose()
    setCurrentSection('transaction')
    setError('')
    setSelectedOption('')
  }
  const handleSubmit = async () => {
     if (selectedOption === "bank") {
      if (!validateFieldsForBank()) {
        
        return;
      }
      
    }
    if (selectedOption === "cheque") {
      if (!validateFieldsForCheque()) {
        return;
      }
    }
    if (selectedOption === "cash" && paymentAmountCash === '') {
      setError('Cash amount is required');
      return;
    } else {
      setError(''); // Clear error if conditions are met
      // Proceed with submission logic
    }
  let paymentDetails = {
    orderId: orderDetail.orderId, // Example order ID, replace with dynamic one
    paymentDoneAmount: paymentAmountBank || paymentAmountCheque || paymentAmountCash,
    paymentMethod: selectedOption,
    duePayment: orderDetail.Duepayment,
    Total:orderDetail.Total
, // Example, replace dynamically
    transactionDate: "2024-07-15T10:00:00Z", // Example, replace with dynamic date
  };

  // Build the bankDetails or chequeDetails or cashDetails based on selected payment method
  if (selectedOption === "bank") {
    paymentDetails.bankDetails = {
      bankName,
      accountHolderName: accountHolderNameBank,
      accountNumber,
      ifscCode,
      paymentAmount: paymentAmountBank
    };
  } else if (selectedOption === "cheque") {
    paymentDetails.chequeDetails = {
      bankName: bankNameCheque,
      accountHolderName: accountHolderNameCheque,
      chequeNumber,
      paymentAmount: paymentAmountCheque,
      accountNumber:accountNumbercheque
    };
  } else if (selectedOption === "cash") {
    paymentDetails.cashDetails = {
      paymentAmount: paymentAmountCash
    };
  }

  try {
    const response = await fetch(`${BaseUrl}/payments/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentDetails),
    });

    const data = await response.json();
    if (response.ok) {
      console.log('Payment request successful', data);
      navigate('/MyOrders')
      handleClosetheModel()
      window.location.reload()
    } else {
      console.error('Error in payment request', data);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  };
  const handleTabChange = (e) => {
    setSelectedOption(e.target.value);
    setBankName("")
    setAccountHolderNameBank("")
    setAccountNumber("")
    setAccountNumbercheque("")
    setIfscCode("")
    setPaymentAmountBank("")
    setBankNameCheque("")
    setAccountHolderNameCheque("")
    setChequeNumber("")
    setPaymentAmountCheque("")
    setPaymentAmountCash("")
    setError("")
    setErrors({
       bankName: "",
    accountHolderNameBank: "",
    accountNumber: "",
    ifscCode: "",
    paymentAmountBank: ""
    })
  };
const isOrderPresent = records.some(record => record.orderId === selectedOrderId);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative p-8 bg-white rounded-lg shadow-lg w-[902px] h-[500px] overflow-auto">
        <div className="flex justify-end">
          <MdOutlineCancel className="w-5 h-5 cursor-pointer" onClick={handleClosetheModel} />
        </div>

        {/* Conditionally render sections based on currentSection state */}
        {currentSection === 'transaction' ? (
          <>
            {transactionData.some((transaction) => transaction.PaymentMethod) ? (
              <>
                <div className=' w-[350px] md:w-full'>
                    <p
    style={{
      fontFamily: 'Lexend',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '12px',
      textAlign: 'left',
    }}
    className="mb-4"
  >
    Payment Transactions
  </p>
  <div className="max-w-full overflow-x-auto">
    <table className="min-w-full mt-4 border border-collapse border-gray-200 rounded-lg table-auto">
      <thead className="bg-[#FFF9F0] rounded-lg">
        <tr>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Total Payment</th>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Payment Status</th>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Complete Payment</th>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Payment Mode</th>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Date</th>
          <th className="p-2 text-sm text-left border border-gray-200 md:text-base">Due Payment</th>
        </tr>
      </thead>
      <tbody className="bg-[#FFF9F0] rounded-lg">
        {transactionData
          .filter((transaction) => transaction.PaymentMethod)
          .reverse()
          .map((transaction, index) => (
            <tr key={index}>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">{transaction.Total || 'N/A'}</td>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">
                <button
                  className={`w-full max-w-[120px] h-[43px] rounded-[10px] text-white ${
                    transaction.Duepayment === '0'
                      ? 'bg-green-500' // Green background for 'Paid'
                      : 'bg-[#FF9C00]' // Orange background for 'Pending'
                  }`}
                >
                  {transaction.Duepayment === '0' ? 'Paid' : 'Pending'}
                </button>
              </td>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">
                {Number.parseFloat(transaction.PaymentDoneAmount).toFixed(0) || '0'}
              </td>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">{transaction.PaymentMethod}</td>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">
                {transaction.TransactionDate
                  ? new Date(transaction.TransactionDate).toLocaleDateString()
                  : 'N/A'}
              </td>
              <td className="p-2 text-sm text-center border border-gray-200 md:text-base">
                {transaction.Duepayment === "0" ? (
                  <span className="text-green-600">Paid</span>
                ) : (
                  <span className="text-red-600">
                    {(transaction.Duepayment && parseFloat(transaction.Duepayment)).toFixed(2)}
                  </span>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
                </div>

</>


            ) : (
              <p className='text-center text-gray-600 text-md'>
                Please proceed with the payment of <span className='text-blue-500'>₹{duePaymentAmount}</span> to complete the process. Thank you!
              </p>
            )}
          </>
        ) : (
          <div>
              {/* Add Payment Section */}
              <div className='flex gap-2 text-[17px] font-sans'>
                <p><span className='text-black'>Order ID:</span> <span className='text-gray-600'>{orderDetail.orderId},</span></p>
                 <p><span>Order total Amount:</span> <span className='text-gray-600'>{orderDetail.Total},</span></p> 
                <p><span>Duepayment:</span> <span className='text-gray-600'>
  {orderDetail.Duepayment ? parseFloat(orderDetail.Duepayment).toFixed(2) : '0.00'}
</span>
</p> 
              
               

                
              </div>
            {/* <p style={{ fontWeight: 'bold', fontSize: '20px' }}>Add New Payment</p> */}
            <div className="md:w-[764px]  rounded-[20px] shadow-lg border mt-2">
          <div>
            <div className="flex items-center p-5">
              <input
                type="radio"
                name="transaction"
                value="bank"
                checked={selectedOption === "bank"}
               onChange={handleTabChange}
                className="mr-2"
              />
              <div>
                <p className="font-lexend text-[16px] font-medium leading-[20px] text-left">
                  Bank Transaction
                </p>
                <p className="text-[#6D6D6D] text-[14px]">
                  Pay by Bank Account
                </p>
              </div>
            </div>

            <div>
            {selectedOption === "bank" && (
        <div className="pl-10 md:flex">
                        <div>
                          <div className='justify-between gap-5 md:flex'>
                            <div>
              <p className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left">
                Bank Name<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-2"
                placeholder="Bank Name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
              {errors.bankName && <p className="mt-2 text-xs text-red-500">{errors.bankName}</p>}
            </div>
            <div className="">
              <p className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left">
                Account Holder Name<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-2"
                placeholder="Account Holder Name"
                value={accountHolderNameBank}
                onChange={(e) => setAccountHolderNameBank(e.target.value)}
              />
              {errors.accountHolderNameBank && <p className="mt-2 text-xs text-red-500">{errors.accountHolderNameBank}</p>}
            </div>
                          </div>
            
            <div className="justify-between mt-2 md:flex">
              <div>
      <p className="font-dm-sans text-[16px] font-medium  text-left">
        Account Number<span className="text-red-500">*</span>
      </p>
      <input
        type="text"
        className="p-2 mt-2 bg-gray-100 border rounded-full md:w-[274px]"
        placeholder="Account Number"
        value={accountNumber}
        onChange={handleAccountNumberChange}
      />
      {errors.accountNumber && (
        <p className="mt-2 text-xs text-red-500">{errors.accountNumber}</p>
      )}
    </div>
              <div>
                <p className="font-dm-sans text-[16px] font-medium  text-left">
                  IFSC Code<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  className="p-2 mt-2 bg-gray-100 border rounded-full md:w-[274px]"
                  placeholder="IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                />
                {errors.ifscCode && <p className="mt-2 text-xs text-red-500">{errors.ifscCode}</p>}
              </div>
            </div>
            <div className="gap-5 mt-2 mb-2 ">
      <p className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left mt-4">
        Payment Done Amount<span className="text-red-500">*</span>
      </p>
      <input
        type="text" // Using 'text' to control input format manually
        className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-2"
        placeholder="Payment Done Amount"
        value={paymentAmountBank}
        onChange={handleAmountChange}
      />
      {errors.paymentAmountBank && (
        <p className="mt-2 text-xs text-red-500">{errors.paymentAmountBank}</p>
      )}
    </div>
           
          </div>
        </div>
      )}
            </div>
          </div>
          <hr />
          <div className="p-5">
            <div className="flex items-center">
              <input
                type="radio"
                name="transaction"
                value="cheque"
                checked={selectedOption === "cheque"}
                onChange={handleTabChange}
                className="mr-2 cursor-default"
              />
              <div>
                <p className="font-lexend text-[16px] font-medium leading-[20px] text-left">
                  Cheque Transaction
                </p>
                <p className="text-[#6D6D6D] text-[14px]">Pay by Cheque Book</p>
              </div>
            </div>
             {selectedOption === "cheque" && (
        <div className="flex pl-5">
                      <div>
                        <div  className='justify-between gap-5 mt-2 md:flex'>
                         <div>
              <p className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left">
                Bank Name<span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-2"
                placeholder="Bank Name"
                value={bankNameCheque}
                onChange={(e) => handleInputChange(e, setBankNameCheque)}
              />
              {errors.bankNameCheque && <p className="mt-2 text-xs text-red-500">{errors.bankNameCheque}</p>}
            </div>
                         <div>
                <label className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left">
                  Account Holder Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-2"
                  placeholder="Account Holder Name"
                  value={accountHolderNameCheque}
                  onChange={(e) => handleInputChange(e, setAccountHolderNameCheque)}
                />
                {errors.accountHolderNameCheque && <p className="mt-2 text-xs text-red-500">{errors.accountHolderNameCheque}</p>}
              </div>
                        </div>
           
            <div className='gap-5 md:flex'>
             
                <div>
      <p className="font-dm-sans text-[16px] font-medium  text-left">
        Cheque Number<span className="text-red-500">*</span>
      </p>
      <input
        type="text"
        className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-1"
        placeholder="Cheque Number"
        value={chequeNumber}
        onChange={(e) => handleInputChangeForcheckNumber(e, setChequeNumber)}
      />
      {errors.chequeNumber && <p className="mt-2 text-xs text-red-500">{errors.chequeNumber}</p>}
                          </div>
                        <div className='mt-1'>
      <p className="font-dm-sans text-[16px] font-medium  text-left ">
        Account Number<span className="text-red-500">*</span>
      </p>
      <input
        type="text"
        className="p-2 mt-2 bg-gray-100 border rounded-full md:w-[274px]"
        placeholder="Account Number"
        value={accountNumbercheque}
        onChange={handleAccountNumberChangeCheque}
      />
       {errors.accountNumbercheque && <p className="mt-2 text-xs text-red-500">{errors.accountNumbercheque}</p>}
    </div>
                        </div>
                         
           <div className="mt-2">
      <p className="font-dm-sans text-[16px] font-medium  text-left">
        Payment Done Amount<span className="text-red-500">*</span>
      </p>
      <input
        type="text"
        className="p-2 bg-gray-100 border rounded-full md:w-[274px] mt-1"
        placeholder="Payment Done Amount"
        value={paymentAmountCheque}
        onChange={(e) => handleInputChangeForCheckAmount(e, setPaymentAmountCheque)}
      />
      {errors.paymentAmountCheque && <p className="mt-2 text-xs text-red-500">{errors.paymentAmountCheque}</p>}
    </div>
           
          </div>
        </div>
      )}
          </div>
          <hr />
          <div className="flex items-center p-5">
            <input
              type="radio"
              name="transaction"
              value="cash"
              checked={selectedOption === "cash"}
             onChange={handleTabChange}
              className="mr-2"
            />
            <div>
              <p className="font-lexend text-[16px] font-medium leading-[20px] text-left">
                Cash On Delivery
              </p>
                  </div>
                 
                </div>
                 {selectedOption === "cash" && (
                    <div className='pl-5'>

<div className="gap-5 mb-2 ">
  <p className="font-dm-sans text-[16px] font-medium leading-[20.83px] text-left mt-2">
    Payment Done Amount<span className="text-red-500">*</span>
  </p>
  <input
    type="text"
    className="p-2 bg-gray-100 border rounded-full w-[274px] mt-2"
    placeholder="Payment Done Amount"
    value={paymentAmountCash}
                        // onChange={handleInputChangeForCashAmount}
                        onChange={(e) => handleInputChangeForCashAmount(e, setPaymentAmountCheque)}
  />
    {errors
.paymentAmountCash
 && <p className="mt-2 text-xs text-red-500">{errors
.paymentAmountCash
}</p>}
                       
</div>
                    </div>
                  )}
          <div className="p-5">
            <p className="font-dm-sans text-base font-medium leading-[20.83px] text-left text-[#6D6D6D]">
              Safe and Secure Payments. Easy returns. 100% Authentic product.
            </p>
          </div>
        </div>

          </div>
        )}

        <div className="flex flex-col mt-4">
          {duePaymentnew > 0 && currentSection === 'transaction' ? (
            <>
            
            
             <div className='flex items-center justify-center'>

<button
  className={`relative overflow-hidden px-4 py-2 text-white rounded ${
    isOrderPresent ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#FF9C00]'
  }`} // Change color based on disabled state
  onClick={() => setCurrentSection('addPayment')}
  disabled={isOrderPresent}  // Disable the button if order is present
>
  <span className="absolute inset-0 transform -translate-x-full bg-gradient-to-r from-transparent via-white to-transparent opacity-60 animate-shine"></span>
  Add New Payment
</button>

       
            </div>
                {isOrderPresent && (
    <p className="mt-2 text-center ttext-center">Your payment request has already been sent.</p>
  )}   
            
            </>
           
            
          ) : currentSection === 'transaction' ? (
            <p className="font-semibold text-center text-green-600">Your payment is done. Visit again, thank you!</p>
            ) : (
                <div className='flex justify-end gap-5'>
 <button
              className="px-4 py-2 text-white bg-blue-500 rounded"
              onClick={() => setCurrentSection('transaction')} // Switch back to 'Transaction' section
            >
              Back to Transactions
            </button>
 <button
              className="px-4 py-2 text-white bg-blue-500 rounded"
              onClick={handleSubmit} // Switch back to 'Transaction' section
            >
              Send Payment Request 
            </button>
                  
                </div>
           
          )}
        </div>
      </div>
    </div>
  );
}

export default AddNewPayment;
