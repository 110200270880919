import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import Testimonailauserimg from "../../asset/Testimonailauserimg.png";
import { useOrderDetails } from "../../ContextApi/OrderDetailsContext";
function AddingAddress() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const [product, setProduct] = useState([]);
 
 console.log(product,"product details")
  const { orderDetails, fetchProducts, removeProduct } = useOrderDetails();
  const [addressData, setAddressData] = useState({
    addressType: "home", // Default selected type
    currentTab: "Address",
    product: [],
    address: "",
    name:"",
    mobile:"",
    locality: "",
    state: "",
    pincode: "",
    alternatePhone: "",
    city:"",
    landmark: "",
    isShippingSame: false,
  });


  console.log(addressData,"addressData")
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const {
    addressType,
    currentTab,
    address,
    name,
    mobile,
    locality,
    state,
    pincode,
    alternatePhone,
    city,
    landmark,
    isShippingSame,
  } = addressData;

  const location = useLocation();
  useEffect(() => {
    const { productsData } = location.state || {};
    if (productsData) {
      // Sorting productsData by price in ascending order
      const sortedProducts = productsData.sort((a, b) => a.price - b.price);
      setProduct(sortedProducts);
    }
  }, [location.state]); 
  
// const DisplayPaymentOption = () => {
//   const shippingCharges = 0; // Set shipping charges
//   let totalAmount = 0; // Initialize total amount
//   let totalDiscount = 0; // Initialize total discount

//   // Check if products is defined and is an array
//   if (Array.isArray(orderDetails.products) && Array.isArray(product)) {
//     // Iterate through each product in the order details
//     orderDetails.products.forEach(orderProduct => {
//       // Find the corresponding product selection details
//       const productDetail = product.find(p => p.productId === orderProduct._id);

//       if (productDetail) {
//         productDetail.selection.forEach(item => {
//           // Extract weight and quantity
//           const weight = parseFloat(item.size.replace('kg', '')); // Convert size from "30kg" to 30
//           const quantity = item.quantity;

//           // Calculate the discounted price for the product
//           const discountedPrice = orderProduct.discount ? orderProduct.price * (1 - orderProduct.discount / 100) : orderProduct.price;

//           // Calculate the total cost for this item
//           const itemTotalPrice = discountedPrice * weight * quantity;

//           // Update totals
//           totalAmount += itemTotalPrice;

//           // Calculate total discount if applicable
//           if (orderProduct.discount) {
//             const discountAmount = (orderProduct.price - discountedPrice) * weight * quantity;
//             totalDiscount += discountAmount;
//           }
//         });
//       }
//     });
//   }

//   // Calculate the saved amount
//   const savedAmount = totalDiscount; // Total discount saved
//   const finalAmount = totalAmount + shippingCharges; // Total amount after shipping charges

//   // Pass data to PaymentMethod component
//   setCurrentStep(2);
//   navigate("/PaymentMethod", {
//     state: {
//       products: orderDetails.products || [], // Ensure products is an array
//       subtotal: totalAmount.toFixed(2), // Pass subtotal value
//       totalAmount: finalAmount.toFixed(2), // Pass final amount after shipping charges
//       shippingCharges: shippingCharges.toFixed(2), // Pass shipping charges
//       savedAmount: savedAmount.toFixed(2), // Pass total discount saved
//       addressData: addressData,
//       bagSizeAndQnt:product
//     },
//   });
// };
const confirmDelete = (id) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      // Call the removeProduct function to delete the product
      removeProduct(id);

      // Optionally show a success alert
      Swal.fire(
        'Deleted!',
        'The product has been deleted.',
        'success'
      );
    }
  });
};

const DisplayPaymentOption = () => {
  const shippingCharges = 0; // Set shipping charges
  let totalAmount = 0; // Initialize total amount
  let totalDiscount = 0; // Initialize total discount
  const productTotals = []; // Array to store total for each product

  // Check if products is defined and is an array
  if (Array.isArray(orderDetails.products) && Array.isArray(product)) {
    // Iterate through each product in the order details
    orderDetails.products.forEach(orderProduct => {
      // Find the corresponding product selection details
      const productDetail = product.find(p => p.productId === orderProduct._id);

      let productTotal = 0; // Initialize total for each product

      if (productDetail) {
        productDetail.selection.forEach(item => {
          // Extract weight and quantity
          const weight = parseFloat(item.size.replace('kg', '')); // Convert size from "30kg" to 30
          const quantity = item.quantity;

          // Calculate the discounted price for the product
          const discountedPrice = orderProduct.discount ? orderProduct.price * (1 - orderProduct.discount / 100) : orderProduct.price;

          // Calculate the total cost for this item
          const itemTotalPrice = discountedPrice * weight * quantity;

          // Update product total
          productTotal += itemTotalPrice;

          // Update overall total amount
          totalAmount += itemTotalPrice;

          // Calculate total discount if applicable
          if (orderProduct.discount) {
            const discountAmount = (orderProduct.price - discountedPrice) * weight * quantity;
            totalDiscount += discountAmount;
          }
        });
      }

      // Push the total for this product to the productTotals array
      productTotals.push({
        productId: orderProduct._id,
        productName: orderProduct.productName, // Assuming there's a name property
        productTotal: productTotal.toFixed(2), // Store total cost for this product
      });
    });
  }

  // Calculate the saved amount
  const savedAmount = totalDiscount; // Total discount saved
  const finalAmount = totalAmount + shippingCharges; // Total amount after shipping charges

  // Pass data to PaymentMethod component
  setCurrentStep(2);
  navigate("/PaymentMethod", {
    state: {
      products: orderDetails.products || [], // Ensure products is an array
      subtotal: totalAmount.toFixed(2), // Pass subtotal value
      totalAmount: finalAmount.toFixed(2), // Pass final amount after shipping charges
      shippingCharges: shippingCharges.toFixed(2), // Pass shipping charges
      savedAmount: savedAmount.toFixed(2), // Pass total discount saved
      addressData: addressData,
      bagSizeAndQnt: product,
      productTotals: productTotals, // Pass the product totals
    },
  });
};



  const handleChange = (event) => {
    setAddressData((prev) => ({ ...prev, addressType: event.target.value }));
  };

  const steps = [
    { id: 1, name: "Address" },
    { id: 2, name: "Order Details" },
    { id: 3, name: "Payment Option" },
  ];

  const displayOrderDetails = () => {
    setCurrentStep(1);
    setAddressData((prev) => ({ ...prev, currentTab: "Order Details" }));
  };

  const handleSaveAndContinue = () => {
  debugger
    if (
      addressData.name === "" ||
      addressData.mobile === "" ||
      addressData.address === "" ||
      addressData.locality === "" ||
      addressData.state === "" ||
      addressData.pincode === "" ||
      addressData.alternatePhone === "" ||
      addressData.city === "" ||
      addressData.landmark === ""
    ) {
      setError(true);
      setErrorMsg("Field is Required");
      return;
    }

    if (addressData.mobile.length !== 10) {
  Swal.fire({
    icon: 'error',
    title: 'Invalid Mobile Number',
    text: 'Mobile number must be exactly 10 digits',
  });
  return;
    }
    if (addressData.alternatePhone.length !== 10) {
       Swal.fire({
    icon: 'error',
    title: 'Invalid Mobile Number',
    text: 'Alternate Phone must be exactly 10 digits',
  });
  return;
    }
    // Extract productIds from productsData and pass to fetchProducts
  const productIds = product.map((product) => product.productId);
  fetchProducts(productIds); 
    setCurrentStep(1);
    setAddressData((prev) => ({ ...prev, currentTab: "Order Details" }));
  };

  const handleToggleShippingSame = () => {
    setAddressData((prev) => ({
      ...prev,
      isShippingSame: !prev.isShippingSame,
    }));
  };
const calculateTotal = () => {
  return product.reduce((total, item) => {
    const discountedPrice = item.discount ? item.price * (1 - item.discount / 100) : item.price;
    const itemTotal = item.selection.reduce((sum, sel) => sum + (discountedPrice * sel.quantity), 0);
    return total + itemTotal;
  }, 0);
};

   const handleBackClick = () => {
    navigate(-1); // This navigates to the previous route
  };
  return (
    <div className="flex flex-col pl-[20px]">
      <div className="flex gap-2 p-5 pl-8 md:pl-28">
  <IoArrowBackCircleSharp className="h-10 w-10 mt-1.5 cursor-pointer hover:scale-110" onClick={handleBackClick} />
  {/* <p className="text-gray-500 cursor-pointer text-16">Back</p> */}
</div>

<div className="flex-col items-center hidden mt-10 md:flex md:visible">
  <div className="flex w-full max-w-4xl px-5 md:px-0">
    {steps.map((step, index) => (
      <div key={step.id} className="relative flex items-center flex-1">
        {index > 0 && (
          <div
            className={`${
              index <= currentStep ? "bg-yellow-500" : "bg-gray-300"
            } h-1 flex-grow`}
          />
        )}
        <div className="flex flex-col items-center">
          <div
            className={`${
              index <= currentStep
                ? "bg-yellow-500 text-white"
                : "bg-gray-300 text-gray-500"
            } w-12 h-12 rounded-full flex items-center justify-center`}
          >
            {index + 1}
          </div>
          <span
            className={`mt-2 ${
              index <= currentStep ? "text-yellow-500" : "text-gray-500"
            }`}
          >
            {step.name}
          </span>
        </div>
        {index < steps.length - 1 && (
          <div
            className={`${
              index < currentStep ? "bg-yellow-500" : "bg-gray-300"
            } h-1 flex-grow`}
          />
        )}
      </div>
    ))}
  </div>
</div>


     {currentTab === "Address" && (
  <div className="px-4 sm:px-8 md:px-16 lg:px-28">
    <div>
      <p className="text-2xl font-bold">Delivery Address</p>
    </div>

    <div className="mt-10 mb-20 space-y-4">
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-2">
        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Your Name"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={name}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />
          {addressData.name === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>

        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Mobile number"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={mobile}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && value.length <= 10) {
                setAddressData((prev) => ({
                  ...prev,
                  mobile: value,
                }));
              }
            }}
          />
          {addressData.mobile === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 sm:flex-row sm:gap-2">
        <div className="relative flex-1 mb-4">
          <textarea
            placeholder="Address (Area And Street)"
            className="block w-full h-[110px] py-3 pl-4 pr-12 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-[#F7F7F7]"
            rows="4"
            value={address}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                address: e.target.value,
              }))
            }
          ></textarea>
          {addressData.address === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>

        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Locality"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={locality}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                locality: e.target.value,
              }))
            }
          />
          {addressData.locality === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
          <input
            type="text"
            placeholder="State"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] mt-2 h-[50px]"
            value={state}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                state: e.target.value,
              }))
            }
          />
          {addressData.state === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 sm:flex-row sm:gap-2">
        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Pincode"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={pincode}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,6}$/.test(value)) {
                setAddressData((prev) => ({
                  ...prev,
                  pincode: value,
                }));
              }
            }}
          />
          {addressData.pincode === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>

        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Alternate Phone (Optional)"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={alternatePhone}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value) && value.length <= 10) {
                setAddressData((prev) => ({
                  ...prev,
                  alternatePhone: value,
                }));
              }
            }}
          />
          {addressData.alternatePhone === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4 sm:flex-row sm:gap-2">
        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="City/District/Town"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={city}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                city: e.target.value,
              }))
            }
          />
          {addressData.city === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>

        <div className="relative flex-1 mb-4">
          <input
            type="text"
            placeholder="Landmark (Optional)"
            className="block w-full py-2 pl-4 pr-10 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 bg-[#F7F7F7] h-[50px]"
            value={landmark}
            onChange={(e) =>
              setAddressData((prev) => ({
                ...prev,
                landmark: e.target.value,
              }))
            }
          />
          {addressData.landmark === "" && error && (
            <p className="mt-2 text-red-500 text-[12px]">{errorMsg}</p>
          )}
        </div>
      </div>

      <div className="flex gap-5 mt-2 ">
        <button
          className="w-full sm:w-[274px] bg-[#4C3F35] text-white h-[50px] rounded-lg mt-2"
          onClick={handleSaveAndContinue}
        >
          SAVE AND DELIVER HERE
        </button>
        <button className="w-full sm:w-[131px] bg-[#F7F7F7] text-black h-[50px] rounded-lg mt-2 border" onClick={handleBackClick}>
          CANCEL
        </button>
      </div>
    </div>
  </div>
)}

{currentTab === "Order Details" && (
  <>
    <div className="px-4 md:px-28">
      <div>
        <p
          className="text-left"
          style={{
            fontFamily: "Lexend",
            fontSize: "2rem", // 32px for larger screens
            fontWeight: 700,
            lineHeight: "1.2",
          }}
        >
          Order Details
        </p>
      </div>

      <div className="flex justify-start mt-5">
        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300">
            <thead className="h-[76px]">
              <tr className="bg-[#F2F2F2] border">
                <th className="p-2">Image</th>
                <th className="p-2">Product Name</th>
                <th className="p-2">BagSize & Quantity</th>
                <th className="p-2">MRP/kg</th>
                <th className="p-2">Total</th>
                <th className="p-2">Remove</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.products.map((value) => {
                const discountedPricePerKg = value.discount
                  ? value.price * (1 - value.discount / 100)
                  : value.price;

                const productDetail = product.find(p => p.productId === value._id);
                let totalWeight = 0;
                let totalCost = 0;

                if (productDetail) {
                  productDetail.selection.forEach(item => {
                    const weight = parseFloat(item.size);
                    const quantity = item.quantity;
                    totalWeight += weight * quantity;
                    totalCost += weight * quantity * discountedPricePerKg;
                  });
                }

                return (
                  <tr className="text-center border h-[76px]">
                    <td className="flex justify-center p-2">
                      <img
                        src={value.selectedImages[0] || Testimonailauserimg}
                        alt="Product"
                        className="w-[89px] h-[89px] mr-2 rounded-lg"
                      />
                    </td>
                    <td className="p-2">{value.productName}</td>
                    <td className="p-2">
                      {productDetail ? (
                        productDetail.selection.map((item, index) => (
                          <div key={index}>
                            {item.size} x {item.quantity}
                          </div>
                        ))
                      ) : (
                        <div>No selection</div>
                      )}
                    </td>
                    <td className="p-2">
                      {value.discount ? (
                        <>
                          <del>₹{value.price.toFixed(2)}</del> ₹{discountedPricePerKg.toFixed(2)}
                          <span className="ml-2 text-sm text-gray-500">({value.discount}% off)</span>
                        </>
                      ) : (
                        <>₹{value.price}</>
                      )}
                    </td>
                    <td className="p-2">₹{totalCost.toFixed(2)}</td>
                    <td className="p-2">
                      <button className="text-red-500 hover:text-red-700">
                        <AiOutlineDelete onClick={() => confirmDelete(value._id)} />
                      </button>
                    </td>
                  </tr>
                );
              })}

              {/* Subtotal, Tax, Shipping, and Total rows */}
              <tr className="text-center h-[20px] border">
                <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: 400 }}>
                  Subtotal:
                </td>
                <td className="p-2"></td>
                <td></td>
                <td></td>
                <td></td>
                <td>₹{orderDetails.products.reduce((acc, value) => {
                  const discountedPricePerKg = value.discount ? value.price * (1 - value.discount / 100) : value.price;
                  const productDetail = product.find(p => p.productId === value._id);
                  let totalCost = 0;
                  if (productDetail) {
                    productDetail.selection.forEach(item => {
                      const weight = parseFloat(item.size);
                      const quantity = item.quantity;
                      totalCost += weight * quantity * discountedPricePerKg;
                    });
                  }
                  return acc + totalCost;
                }, 0).toFixed(2)}</td>
              </tr>
              <tr className="text-center border h-[20px]">
                <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: 400 }}>
                  Tax:
                </td>
                <td className="p-2"></td>
                <td></td>
                <td></td>
                <td></td>
                <td>0</td>
              </tr>
              <tr className="text-center border h-[20px]">
                <td colSpan="1" className="p-2 border border-gray-300 text-[14px]" style={{ fontFamily: "Lexend", fontSize: "14px", fontWeight: 400 }}>
                  Shipping:
                </td>
                <td className="p-2"></td>
                <td></td>
                <td></td>
                <td></td>
                <td>0</td>
              </tr>
              <tr className="text-center border h-[20px] font-bold">
                <td colSpan="1" className="p-2 font-bold border border-gray-300 text-[18px]" style={{ fontFamily: "Lexend", fontWeight: 700 }}>
                  Total:
                </td>
                <td className="p-2"></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-[#087E27] text-[18px]">
                  ₹{orderDetails.products.reduce((acc, value) => {
                    const discountedPricePerKg = value.discount ? value.price * (1 - value.discount / 100) : value.price;
                    const productDetail = product.find(p => p.productId === value._id);
                    let totalCost = 0;
                    if (productDetail) {
                      productDetail.selection.forEach(item => {
                        const weight = parseFloat(item.size);
                        const quantity = item.quantity;
                        totalCost += weight * quantity * discountedPricePerKg;
                      });
                    }
                    return acc + totalCost;
                  }, 0).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='flex justify-center mt-10 mb-20'>
        <button
          className={`w-full max-w-[214px] h-[54px] rounded-[27px] text-white ${orderDetails.products.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#FF9F00]'}`}
          onClick={DisplayPaymentOption}
          disabled={orderDetails.products.length === 0}
        >
          PROCEED TO PAYMENT
        </button>
      </div>
    </div>
  </>
)}

    </div>
  );
}

export default AddingAddress;
