import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Testimonailauserimg from '../../asset/Testimonailauserimg.png';
import testimonalbg from '../../asset/testimonalbg.png';
import Testmonialsimage from '../../asset/Testmonialsimage.png';
import Wheateimg from '../../asset/wheateImage.png';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';

function AboutTestinomials() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetTestimonials`);
        const data = await response.json();
        setTestimonialsData(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const currentTestimonial = testimonialsData[currentIndex];

  return (
    <div
      style={{
        backgroundImage: `url(${testimonalbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'auto',
        minHeight: '622px',
        display: 'flex',
        color: '#fff',
        textAlign: 'center',
      }}
      className='flex flex-col items-center justify-center px-6 py-12 mt-16 sm:px-8 md:px-16 lg:px-24'
    >
      <div className="flex flex-col items-center gap-6 lg:flex-row lg:gap-12">
        <div>
          <img src={Testmonialsimage} alt="Testimonials" className="w-[150px] h-[150px] lg:w-[212px] lg:h-[212px]" />
        </div>
        <div className="text-left lg:text-start">
          <p className="flex items-center text-2xl text-[#4C3F35] font-semibold"
             style={{ fontFamily: 'Dancing Script, cursive' }}>
            <img src={Wheateimg} alt="Wheat" className="w-[30px] h-[25px] mr-2" />
            Testimonials
          </p>
          <p className="text-3xl lg:text-4xl font-bold text-[#222222]" style={{ fontFamily: 'Lexend' }}>
            Our Customer Feedback
          </p>

          {/* Ensure that the content is dynamically responsive */}
          <div className="my-4">
            {currentTestimonial && (
              <p
                className="text-lg lg:text-xl italic font-normal leading-relaxed text-left text-[#6D6D6D]"
                style={{ fontFamily: 'DM Sans' }}
                dangerouslySetInnerHTML={{ __html: currentTestimonial.review }}
              />
            )}
          </div>

          <div className='flex mt-8 lg:mt-10'>
            <div className='flex justify-between w-full gap-12 lg:gap-20'>
              <div className='flex items-center gap-5'>
                <div>
                  <img src={Testimonailauserimg} alt={currentTestimonial?.shopName || 'User'} className="w-[60px] h-[60px] lg:w-[79px] lg:h-[79px]" />
                </div>
                <div>
                  <p
                    className="text-lg lg:text-2xl font-medium text-[#222222] truncate"
                    style={{ fontFamily: 'Lexend' }}
                  >
                    {currentTestimonial?.shopName || 'User Name'}
                  </p>
                  <p className="text-sm lg:text-base text-[#4C3F35]" style={{ fontFamily: 'DM Sans' }}>
                    {currentTestimonial?.personName || 'User Role'}
                  </p>
                </div>
              </div>

              {/* Responsive arrow buttons */}
              <div className='flex gap-2'>
                <button
                  className='bg-[#F4D29A] text-black p-2 rounded-full w-10 h-10 lg:w-11 lg:h-11 flex justify-center items-center'
                  onClick={handlePrevClick}
                >
                  <FaArrowLeft />
                </button>
                <button
                  className='bg-[#F4D29A] text-black p-2 rounded-full w-10 h-10 lg:w-11 lg:h-11 flex justify-center items-center'
                  onClick={handleNextClick}
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutTestinomials;
