import React, { useEffect, useState } from 'react';
import AboutBg from '../../asset/AboutBg.png';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
function TermsAndCondition() {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    const fetchTerms = async () => {
      try {
        const response = await fetch(`${BaseUrl}/GetTermsAndCondition`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Assuming the first object contains the content you want to display
        setTerms(data[0]?.content || '');
      } catch (error) {
        console.error('Failed to fetch terms and conditions:', error);
      }
    };

    fetchTerms();
  }, []);

  return (
    <>
    
    <div
      className="h-[297px] w-full bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center p-4 "
      style={{ backgroundImage: `url(${AboutBg})` }}
    >
      <h1
        className="text-[#222222] md:text-[50px] text-[30px] "
        style={{
          fontFamily: 'Lexend',
          // fontSize: '50px',
          fontWeight: 500,
          lineHeight: '40px',
          textAlign: 'left',
              }}

      >
       Terms And Conditions
      </h1>
      <p className="mt-4 text-lg ">Home &gt;<span className='text-[#BE740A]'> Terms And Conditions</span></p>
      </div>
      <div className='flex justify-center mt-10 mb-5'>

 <div className="text-center terms-and-conditions" dangerouslySetInnerHTML={{ __html: terms }} />
      </div>
      
    </>
   
  );
}

export default TermsAndCondition;
