import React, { useContext, useEffect, useState } from 'react';
import { CiHeart } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { MdAddShoppingCart } from "react-icons/md";
import { CartContext } from '../../ContextApi/AddtocartContext';
import { useWishlist } from '../../ContextApi/Context';
import { FaHeart } from "react-icons/fa6";
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import { useNavigate } from 'react-router-dom';
import ViewProduct from '../../Pages/ProductDetails/ViewProduct';
import Swal from 'sweetalert2';
function HomePageProducts({ selectedCategory }) {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isViewProductModalOpen, setIsViewProductModalOpen] = useState(false);
   const {cart, addToCart } = useContext(CartContext);
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
    const navigate = useNavigate();
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null); // Reset error state before fetching
      try {
        const response = await fetch(`${BaseUrl}/products/getEcommerceProducts`);
        const data = await response.json();

        if (data.result) {
          setProducts(data.productsList); // Assuming the API returns products in data.products
        } else {
          setProducts([]); // Clear products if no result
        }
      } catch (error) {
        setError('Error fetching products');
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []); // Only fetch products once when the component mounts

  useEffect(() => {
    if (selectedCategory === "ALL") {
      // Shuffle products and take the first 4
      const shuffled = [...products].sort(() => 0.5 - Math.random());
      setFilteredProducts(shuffled.slice(0, 4));
    } else if (selectedCategory) {
      // Filter products based on selected category
      const filtered = products.filter(product => product.category === selectedCategory);
      setFilteredProducts(filtered.slice(0, 4)); // Take first 4 of the filtered products
    } else {
      setFilteredProducts(products.slice(0, 4)); // If no category is selected, show first 4 products
    }
  }, [products, selectedCategory]); // Depend on products and selectedCategory

  if (loading) {
    return <div>Loading...</div>; // Simple loading indicator
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }
const handleViewProduct = (productId) => {
    setSelectedProductId(productId);
    setIsViewProductModalOpen(true);
  };
   const isProductInWishlist = (productId) => {
    const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    return wishlist.some((item) => item._id === productId);
  };
  const handleCloseViewProduct = () => {
    setIsViewProductModalOpen(false);
  };
//  const toggleWishlist = (product) => {
  

//   let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
//   const productIndex = storedWishlist.findIndex((item) => item._id === product._id);

//   if (productIndex === -1) {
//     // Add product to wishlist
//     const productWithDate = { ...product, dateAdded: new Date().toISOString() };
//     storedWishlist.push(productWithDate);
//     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
//     addToWishlist(productWithDate);
//     alert("Product added to wishlist!");
//   } else {
//     // Remove product from wishlist
//     storedWishlist.splice(productIndex, 1);
//     localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
//     removeFromWishlist(product._id);
//     alert("Product removed from wishlist!");
//   }
//   };
const toggleWishlist = (product) => {
  let storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
  const productIndex = storedWishlist.findIndex((item) => item._id === product._id);

  if (productIndex === -1) {
    // Add product to wishlist
    const productWithDate = { ...product, dateAdded: new Date().toISOString() };
    storedWishlist.push(productWithDate);
    localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    addToWishlist(productWithDate);

    // Show SweetAlert for adding to wishlist
    Swal.fire({
      icon: 'success',
      title: 'Added to Wishlist',
      text: 'Product added to your wishlist!',
      showConfirmButton: false,
      timer: 1500
    });
  } else {
    // Remove product from wishlist
    storedWishlist.splice(productIndex, 1);
    localStorage.setItem("wishlist", JSON.stringify(storedWishlist));
    removeFromWishlist(product._id);

    // Show SweetAlert for removing from wishlist
    Swal.fire({
      icon: 'info',
      title: 'Removed from Wishlist',
      text: 'Product removed from your wishlist.',
      showConfirmButton: false,
      timer: 1500
    });
  }
};
    const NavigateToShopDetails = async (productId) => {
  if (productId) {
    try {
      const response = await fetch(`${BaseUrl}/products/GetSingleproductsForEcommerce`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // setProductDetails(data.SingleProductList);

      // Navigate to ShopDetails with the product details
      navigate("/ShopDetails", { state: { productId, productDetails: data.SingleProductList } });
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  }
};
  return (
    // <div className='flex justify-center gap-5 hover:cursor-pointer'>
     <div className="grid justify-center grid-cols-1 gap-5 pl-10 sm:grid-cols-2 lg:flex md:pl-0">
      {/* Show only the first 4 products */}
      {filteredProducts.map((product) => (
        <div 
          key={product.id} 
          className='relative w-[274px] h-[415px] bg-white p-5 rounded-lg transition-shadow duration-300 hover:scale-105'
          onMouseEnter={() => setHoveredProductId(product._id)}
          onMouseLeave={() => setHoveredProductId(null)}
        >
          <div className='h-[242px] bg-[#FFF9F0] p-5 rounded-lg'>
            {/* <button
              style={{
                fontFamily: 'DM Sans, sans-serif',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18.23px',
                textAlign: 'left',
              }}
              className='bg-[#735944] text-white p-1 rounded-lg'
            >
              {product.quailty}
            </button> */}
            <div className='relative flex'>
              <img
                src={product.selectedImages[0]} // Use the first image or a default one
                alt="Product"
                className="w-[173px] h-[172px] mt-2 rounded-lg"
              />
              {/* Only render icons if the product is hovered */}
               {hoveredProductId === product._id && (
                  <div className="absolute top-0 right-0 flex flex-col items-end transition-transform duration-300">
                    <button
                      className="w-[51px] h-[51px] rounded-full bg-white mb-2 flex justify-center items-center hover:bg-[#FF9F00] hover:text-white"
                      onClick={() => handleViewProduct(product._id)}
                    >
                      <IoEyeOutline className="w-[28px] h-[28px]" />
                    </button>
                    {/* <button
        className="w-[51px] h-[51px] rounded-full bg-white mb-2 flex justify-center items-center hover:bg-[#FF9F00] hover:text-white"
        onClick={() => handleAddToCart(product)}
      >
        <MdAddShoppingCart className="w-[28px] h-[28px]" />
      </button> */}
                  

<button
  className={`w-[51px] h-[51px] rounded-full flex justify-center items-center  
    ${isProductInWishlist(product._id) ? 'text-black bg-white' : 'text-black bg-white'}
     hover:text-white`}
  onClick={() => toggleWishlist(product)}
>
  {isProductInWishlist(product._id) ? (
    <FaHeart className={`w-[22px] h-[22px] text-red-600`} />
  ) : (
    <CiHeart className={`w-[28px] h-[28px] text-black`} />
  )}
</button>


                  </div>
                )}
            </div>
          </div>
          <div>
            <p
          style={{
            fontFamily: "DM Sans, sans-serif",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18.23px",
          }}
          className="mt-2 text-[#6D6D6D] text-center"
        >
          {product.category}
        </p>
        <p
          style={{
            fontFamily: "Lexend, sans-serif",
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "42px",
          }}
          className="mt-2 text-center"
        >
          {product.productName.length > 7
            ? `${product.productName.slice(0, 7)}...`
            : product.productName}
        </p>
           <div className="flex justify-center gap-2">
                <p
                  style={{
                    fontFamily: "DM Sans, sans-serif",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "18.23px",
                  }}
                  className="text-[#6D6D6D] text-center"
                >
                  {product.discount ? (
                    <del>₹{product.price.toFixed(2)}</del>
                  ) : (
                    `₹${product.price.toFixed(2)}`
                  )}
                </p>

                {product.discount && (
                  <p
                    style={{
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "18.23px",
                    }}
                    className="text-[#6D6D6D] text-center"
                  >
                    ₹{(product.price * (1 - product.discount / 100)).toFixed(2)}
                  </p>
                )}
                {product.discount && (
                  <button
                    style={{
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14px",
                      fontWeight: 700,
                      textAlign: "left",
                      position: "relative",
                      top: "-5px",
                    }}
                    className="bg-[#735944] text-white p-1 rounded-lg"
                  >
                    {`${product.discount}% OFF`}
                  </button>
                )}
              </div>
            <div className='text-center'>
              {hoveredProductId === product._id ? (
               <button
  className="w-[118px] h-[41px] bg-[#FF9F00] text-white rounded-[50px] p-2"
  onClick={() => NavigateToShopDetails(product._id)} // pass the productId here
>
  SHOP NOW
</button>
              ) : (
          <div className="flex items-center justify-center gap-2">
  <p>({product.reviewCount || 0} Reviews)</p>
  <div className="flex items-center">
    {(() => {
      // Ensure averageRating is a valid non-negative integer
      const ratingCount = Math.max(0, Math.floor(Number(product.averageRating) || 0));

      return (
        <>
          {[...Array(ratingCount)].map((_, index) => (
            <svg
              key={index}
              className="w-4 h-4 text-yellow-300 me-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path d="M10.868.793l2.137 6.58h6.936a.3.3 0 0 1 .176.543l-5.613 4.08 2.136 6.579a.3.3 0 0 1-.46.335L11 14.373l-5.19 3.87a.3.3 0 0 1-.46-.335l2.136-6.579-5.613-4.08a.3.3 0 0 1 .176-.543h6.936z" />
            </svg>
          ))}
        </>
      );
    })()}
  </div>
</div>

          )}
            </div>
          </div>
        </div>
      ))}

       {isViewProductModalOpen && (
        <ViewProduct isModalOpen={isViewProductModalOpen} productId={selectedProductId} onClose={handleCloseViewProduct} />
      )}
    </div>
  );
}

export default HomePageProducts;