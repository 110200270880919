import React, { useEffect, useState } from 'react';
import { GrNext } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import Loader from '../../Componenet/Loader';
import AddNewpayment from '../../Componenet/Models/AddNewpayment';
import { BaseUrl } from '../../ContextApi/Url/BaseUrl';
import ReturnProductApply from './ReturnProductApply';
import PolicyModal from './ReturnWindowPolicy';
import CartEmpty from '../../asset/CartEmpty.png';
function MyOrderPage() {
  const [rating, setRating] = useState(0);
  const [ismodelOpen, setIsModelOpen] = useState(false)
  const [selectedOrderId, setselectedOrderId] = useState("")
  const [selectedOrder, setselectedOrder] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  console.log(orders,"orders")
  const [isModelOpenForReturn, setIsModelOpenForReturn] = useState(false);
  const [returnData, setReturnData] = useState({
    product: null,
    orderId: null,
    customerInfo: null,
    Duepayment: null,
    totalAmount: null,
    DispatchStatus: null,
    shopId:null
  });
   const [selectedStatus, setSelectedStatus] = useState('All');
  console.log(selectedStatus,"selectedStatus")
 
   const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
   const handleRating = (rate) => {
    setRating(rate);
  };
  const HandleClickPaymentTransaction = (order) => {
     setselectedOrder(order)
    setselectedOrderId(order.orderId);
    
    // Use setTimeout to ensure the state update is processed
    setTimeout(() => {
      if (order.orderId) {
        setIsModelOpen(true);
      }
    }, 0);
  };
  const HandleClickOffPaymentTrasaction = () => {
    setIsModelOpen(false)
  }
   useEffect(() => {
 
     const fetchOrders = async () => {
    setLoading(true)
    try {
      // Get dealerInfo from localStorage
     const dealerInfo = JSON.parse(localStorage.getItem('dealerInfo'));; // Replace 'dealerInfo' with the actual key used in localStorage

      if (!dealerInfo || !dealerInfo.shopId) {
        console.error('Dealer Not Found');
        return;
      }

      const shopId = dealerInfo.shopId; // Extract shopId from dealerInfo
      const token = localStorage.getItem('authToken'); // Replace with the actual token key

      // Fetch the orders for the specific shopId
      const response = await fetch(`${BaseUrl}/Order/getOrdersByShopId/${shopId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is okay
      if (!response.ok) {
         setLoading(false)
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Check if the data result is successful and set orders
      if (data.success) {
        setOrders(data.OrderList.reverse()); // Update the orders state
         setLoading(false)
      } else {
         setLoading(false)
        console.error('Failed to fetch orders');
      }
    } catch (error) {
       setLoading(false)
      console.error('Error fetching orders:', error);
    }
  };

  // Call the fetchOrders function
  fetchOrders();
   }, []); 

 
const formatDateToIST = (date) => {
  if (!date) return 'TBD';
  
  // Parse the date string and create a Date object
  const dateObject = new Date(date);

  // Options for date formatting
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'Asia/Kolkata',
  };

  // Format the date according to IST
  return dateObject.toLocaleDateString('en-IN', options);
  };
  
  const formatDateAfterDays = (dateStr, days) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        date.setDate(date.getDate() + days);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
  };
  

  const handleNavigate = (product, orderId, customerInfo, Duepayment) => {
      debugger
    // Navigate and pass the product details as state
    navigate('/ViewOrderDetails', { state: { product,orderId,customerInfo,Duepayment } });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // filterOrders(value, filterStatus); // Apply both search and status filter
  };
  
   const onClickOpenTheReturn = (product, orderId, customerInfo, Duepayment,totalAmount,DispatchStatus,shopId) => {
    // Set the data when opening the modal
    setReturnData({ product, orderId, customerInfo, Duepayment,totalAmount ,DispatchStatus,shopId});
    setIsModelOpenForReturn(true);
  };
  const onClickCloseTheReturn = () => {
    setIsModelOpenForReturn(false)
  }
const handleCloseModal = () => setShowModal(false);
  const handleButtonClick = (order) => {
  if (order.ReturnApply) {
    // Show modal when the button is visually "disabled"
    setShowModal(true);
  } else {
    // Handle payment transaction
    HandleClickPaymentTransaction(order);
  }
  };
  
  const handleStatusChange = async (status) => {
  // Update the selected status in state
  setSelectedStatus(status);

  // Get dealerInfo from localStorage
  const dealerInfo = JSON.parse(localStorage.getItem('dealerInfo')); // Replace 'dealerInfo' with the actual key used in localStorage

  if (!dealerInfo || !dealerInfo.shopId) {
    console.error('Dealer Not Found');
    return;
  }

  const shopId = dealerInfo.shopId;

  // Only call the API if the status is NOT "All"
  if (status !== "All") {
    const token = localStorage.getItem('authToken'); // Replace with the actual token key

    try {
      const response = await fetch(`${BaseUrl}/Order/getOrdersByShopIdWithStatus/${shopId}?status=${status}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Check if the data result is successful and set orders
      if (data.success) {
        setOrders(data.OrderList.reverse()); // Update the orders state
      } else {
        console.error('Failed to fetch orders');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }
};
const filteredOrders = orders.filter((factory) =>
    factory.orderId.toLowerCase().includes(searchTerm.toLowerCase())
  );

console.log(filteredOrders,"fillterrecords")
  return (
    <div className='justify-center gap-2 p-10 md:p-20 md:flex'>
      <div className='md:w-[274px] md:h-[564px] rounded-[12px] border'>
        <p className='text-[30px] p-5 font-[500] ' style={{ fontFamily: 'DM Sans' }}>Filters</p>
        <hr className='border-1'></hr>
       <div className='p-5 '>
  <p className='text-[20px] font-[500]' style={{ fontFamily: 'DM Sans' }}>ORDER STATUS</p>
 <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
            
    <input
      type="radio"
      className='mr-2 cursor-pointer accent-white'
      checked={selectedStatus === 'All'}
      onChange={() => handleStatusChange('All')}
      name="orderStatus"
    />
    <label className={`cursor-pointer ${selectedStatus === 'All' ? 'font-bold text-black' : ''}`}>All</label>
  </div>
  <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
    <input
      type="radio"
      className='mr-2 cursor-pointer accent-white'
      checked={selectedStatus === 'OrderPlace'}
      onChange={() => handleStatusChange('OrderPlace')}
      name="orderStatus"
    />
    <label className={`cursor-pointer ${selectedStatus === 'OrderPlace' ? 'font-bold text-black' : ''}`}>OrderPlace</label>
  </div>

          <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
            
    <input
      type="radio"
      className='mr-2 cursor-pointer accent-white'
      checked={selectedStatus === 'delivered'}
      onChange={() => handleStatusChange('delivered')}
      name="orderStatus"
    />
    <label className={`cursor-pointer ${selectedStatus === 'delivered' ? 'font-bold text-black' : ''}`}>Delivered</label>
  </div>

  <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
    <input
      type="radio"
      className='mr-2 cursor-pointer accent-white'
      checked={selectedStatus === 'cancelled'}
      onChange={() => handleStatusChange('cancelled')}
      name="orderStatus"
    />
    <label className={`cursor-pointer ${selectedStatus === 'cancelled' ? 'font-bold text-black' : ''}`}>Cancelled</label>
  </div>

  <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
    <input
      type="radio"
      className='mr-2 cursor-pointer accent-white'
      checked={selectedStatus === 'returned'}
      onChange={() => handleStatusChange('returned')}
      name="orderStatus"
    />
    <label className={`cursor-pointer ${selectedStatus === 'returned' ? 'font-bold text-black' : ''}`}>Returned</label>
  </div>
</div>
        


        {/* <hr className='border-1'></hr> */}
        {/* <div className='p-5'>
          <p className='text-[20px] font-[500]' style={{ fontFamily: 'DM Sans' }}>ORDER TIME</p>
          <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
           <input
    type="checkbox"
    className='mr-2 accent-white' // Adjust the checkbox color
  />
            <p className=''>Last 30 days</p>
          </div>
          <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
           <input
    type="checkbox"
    className='mr-2 accent-white' // Adjust the checkbox color
  />
            <p className=''>2023</p>
          </div>
          <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
            <input
    type="checkbox"
    className='mr-2 accent-white' // Adjust the checkbox color
  />
            <p className=''>2024</p>
          </div>
           <div className='text-[#6D6D6D] text-[18px] flex gap-2 mt-2'>
            <input
    type="checkbox"
    className='mr-2 accent-white' // Adjust the checkbox color
  />
            <p className=''>Older</p>
          </div>
          
        </div> */}
      </div>
      <div className='md:w-[862px] md:h-[400px] '>

<form className="max-w-[862px] w-full rounded-[12px] mx-auto mt-5 md:mt-0">
  <label
    htmlFor="default-search"
    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
  >
    Search
  </label>
  <div>
    <input
      type="search"
      id="default-search"
      value={searchTerm}
      onChange={handleSearchChange}
      className="w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="Search your Order here"
      required
    />
  </div>
</form>

      <div className='md:w-[1000px] md:h-[500px] overflow-auto w-full'>
  {filteredOrders.map((order, orderIndex) =>
    order.ProductDetails.filter((product) => {
      if (selectedStatus === 'All') return true;
      if (selectedStatus === 'OrderPlace') return product?.dispatchShippingDetails.OrderStatus === 'Pending';
      if (selectedStatus === 'cancelled') return product.dispatchShippingDetails.OrderStatus === 'Canceled';
      if (selectedStatus === 'delivered') return product.dispatchShippingDetails.DispatchStatus === 'Completed';
      if (selectedStatus === 'returned') return product.dispatchShippingDetails.DispatchStatus === 'Return';
      return false;
    }).map((product, productIndex) => (
      <div key={`${order._id}-${productIndex}`} className="mt-5 w-full md:w-[862px] h-auto border rounded-[12px] bg-gray-50 p-4">
        <div className="flex flex-col gap-5 md:flex-row">
          <p className="mt-2 font-semibold">{order?.orderId}</p>
          {product?.dispatchShippingDetails?.DispatchID && (
            <p className="mt-2 font-semibold">
              Dispatch ID - {product.dispatchShippingDetails.DispatchID}
            </p>
          )}
        </div>
        <div className="bg-[#FFF9F0] w-full h-[25px] mt-2 p-1 rounded-[30px] hidden md:visible">
          <p className="text-[#6D6D6D] text-[12px] ">Aakar Customer shared this order with you.</p>
        </div>
        <div className="flex flex-col items-start justify-between mt-5 md:flex-row">
          <div className='pl-[35%] md:pl-0'>
            <img src={product.selectedImages[0]} alt="Product" className="w-[65px] h-[65px] rounded-lg mb-2 md:mb-0 " />
            
        </div>
    
            
         
          
          <div className="flex-1">
            <p className="font-sans text-[18px] font-[400] text-left">{product.ProductName}</p>
            {product.selection.map((selection, sIdx) => (
              <p key={sIdx} className="text-[15px]">BagSize: {selection.size}, QTY: {selection.quantity}</p>
            ))}
          </div>
          <div>
            <p className="font-sans text-[18px] font-[400] text-left">₹{parseFloat(product.productTotalAmount).toFixed(2)}</p>
          </div>
          <div className="text-left">
            <p className="font-sans text-[18px] font-[400]">Delivered on {formatDateToIST(product?.dispatchShippingDetails?.EstimatedDeliveryDate) || 'TBD'}</p>
            {product?.dispatchShippingDetails?.DispatchStatus !== 'Completed' && (
              <p className="text-[#6D6D6D]">Expected by {formatDateToIST(product?.dispatchShippingDetails?.EstimatedDeliveryDate) || 'TBD'}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5 mt-2 md:flex-row">
        
          <div className="flex gap-2">
            <p className="mt-2 font-medium">Order Status:</p>
            <button className={`h-[30px] border rounded-[10px] mt-2 px-4 ${
              product.dispatchShippingDetails.OrderStatus === 'Canceled'
                ? 'bg-red-500 text-white border-red-500'
                : product.dispatchShippingDetails.DispatchStatus === 'Return'
                ? 'bg-red-500 text-white border-red-500'
                : product.OrderTrackingDetails?.Delivered
                ? 'bg-green-500 text-white border-green-500'
                : product.OrderTrackingDetails?.Out_for_Delivery
                ? 'bg-blue-500 text-white border-blue-500'
                : product.OrderTrackingDetails?.Shipped
                ? 'bg-purple-500 text-white border-purple-500'
                : product.OrderTrackingDetails?.Place
                ? 'bg-yellow-500 text-white border-yellow-500'
                : 'bg-gray-300 text-gray-700 border-gray-300'
            }`}>
              {product.dispatchShippingDetails.OrderStatus === 'Canceled'
                ? 'Canceled'
                : product.dispatchShippingDetails.DispatchStatus === 'Return'
                ? 'Returned'
                : product.OrderTrackingDetails?.Delivered
                ? 'Delivered'
                : product.OrderTrackingDetails?.Out_for_Delivery
                ? 'Out for Delivery'
                : product.OrderTrackingDetails?.Shipped
                ? 'Shipped'
                : product.OrderTrackingDetails?.Place
                ? 'Order Placed'
                : 'Pending'}
            </button>
          </div>

         
          <div className="flex gap-2">
            {product.dispatchShippingDetails.DispatchStatus !== 'Return' && (
              <>
                <p className="mt-2 font-medium">Payment Status :</p>
                <button className={`w-[80px] h-[30px] border rounded-[10px] mt-2 ${
                  order.ReturnApply
                    ? 'border-gray-400 text-gray-400 cursor-pointer'
                    : order.Duepayment === '0'
                    ? 'border-green-500 text-green-500'
                    : 'border-orange-500 text-orange-500'
                }`} onClick={() => handleButtonClick(order)}>
                  {order.ReturnApply ? 'Pending' : order.Duepayment === '0' ? 'Paid' : 'Pending'}
                </button>
              </>
            )}
          </div>
        </div>
        <hr className="mt-5 border-1" />
        <div className="flex gap-2 mt-2">
          <div className="w-[10px] h-[10px] bg-[#4C3F35] rounded-full mt-3"></div>
          <p className="text-[16px] mt-1">
            Return window Policy <span className='text-blue-800 cursor-pointer hover:underline' onClick={openModal}> Click Here</span>
          </p>
        </div>
        <hr className="mt-5 border-1" />
        <div className="flex justify-end">
          <div className="flex gap-2 pr-2">
            <button className={`w-[92px] h-[43px] rounded-lg text-white p-2 mt-2 ${
              product.OrderTrackingDetails?.Delivered && order.Duepayment === order.Total && product.dispatchShippingDetails?.DispatchStatus
                ? 'bg-[#567FC5]'
                : 'bg-gray-400 cursor-not-allowed'
            }`} onClick={() => {
              if (product.OrderTrackingDetails?.Delivered && order.Duepayment === order.Total && product.dispatchShippingDetails?.DispatchStatus) {
                onClickOpenTheReturn(product, order.orderId, order.customerInfo, order.Duepayment, order.Total, product.dispatchShippingDetails.DispatchStatus, order.ShopId);
              }
            }} 
            disabled={!(product.OrderTrackingDetails?.Delivered && order.Duepayment === order.Total && product.dispatchShippingDetails?.DispatchStatus)}>
              Return
            </button>
            <button className="w-[156px] h-[43px] bg-[#4C3F35] text-white rounded-lg p-3 mt-2 flex items-center gap-4 justify-between" onClick={() => handleNavigate(product, order._id, order.customerInfo, order.Duepayment)}>
              <span>Order Details</span> <p><GrNext /></p> 
            </button>
          </div>
        </div>
      </div>
    ))
  )}

  <div className="w-full md:w-[862px] h-[360px] rounded-[12px] flex justify-center items-center">
    {filteredOrders.length < 1 && (
      <img src={CartEmpty} alt="Wheat" className="h-[200px] w-[150px]" />
    )}
  </div>
</div>

       

       
      </div>
      {showModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-lg font-semibold text-center">Return Request Pending</h2>
            <p className='flex mt-5 text-center'>Your return request is already in process. You cannot proceed <br></br>with the payment or any other action until this request is completed.</p>
            <div className='flex justify-center mt-5'>
              <button
        className="px-4 py-2 mt-4 text-white bg-blue-500 rounded"
        onClick={handleCloseModal} // Close the modal
      >
        OK
      </button>
              </div>
      
    </div>
  </div>
)}
 {isModelOpenForReturn && returnData && returnData.product && returnData.orderId && returnData.customerInfo && returnData.DispatchStatus && (
  <ReturnProductApply
    onClickCloseTheReturn={onClickCloseTheReturn}
    product={returnData.product}
    orderId={returnData.orderId}
    customerInfo={returnData.customerInfo}
          Duepayment={returnData.Duepayment}
          totalAmount={returnData.Duepayment}
          DispatchStatus={returnData.DispatchStatus}
          shopId={returnData.shopId}
  />
      )}
       {loading && <Loader loading={loading}  />}
 <PolicyModal isOpen={isModalOpen} onClose={closeModal} />
      <AddNewpayment isModalOpen={ismodelOpen} onClose={HandleClickOffPaymentTrasaction} selectedOrderId={selectedOrderId} orderDetail={selectedOrder} />
    </div>
  )
}

export default MyOrderPage
